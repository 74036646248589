import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { t } from "i18next";
import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Theme from "../../Theme";
import TableOverview from "../../reusableComponents/TableComponent/TableOverview";
import Tabs from "../../reusableComponents/Tabs";
import {
  CatalogSvg,
  HomeSvg,
  TransformationSvg,
} from "../../reusableComponents/svg/menuListSvg";
import { StyledPortalContent } from "../../styles/Styled";
import { TableDataCell } from "../../styles/Yedaflow.Styled";

const mockedData = Array.from({ length: 3 }, () => ({
  id: Math.floor(Math.random() * 10000),
  creationTime: new Date().toUTCString(),
  creatorUserId: 57,
  lastModificationTime: "Nothing to show",
  lastModifierUserId: "Nothing to show",
}));

const TabsContainer = styled.div`
  margin-bottom: 24px;
`;

const DescriptionContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const TagContainer = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 16px;
  align-items: center;
`;

const PageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TablesTabs = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
  margin-top: 12px;
`;

export const CatalogDetailsOverview = () => {
  const [selectedTable, setSelectedTable] = useState("Columns");
  const [tag, setTag] = useState("");
  const { database: databaseName, table: tableName } = useParams();

  const fill = Theme.colors.blueColor;
  const size = 18;
  const tabData = [
    {
      to: "/dataManagement",
      icon: <HomeSvg fill={fill} width={size} height={size} />,
      label: t("data_management.catalog.header.home"),
    },
    {
      to: "/transformation",
      icon: <TransformationSvg fill={fill} width={size} height={size} />,
      label: t("data_management.catalog.header.transformation"),
    },
    {
      to: "/catalog",
      icon: <CatalogSvg fill={fill} width={size} height={size} />,
      label: t("data_management.catalog.header.catalog"),
    },
  ];

  const tables = {
    Columns: {
      title: "Columns",
      component: (
        <div
          style={{
            display: "flex",
            gap: 8,
            flexDirection: "column",
          }}
        >
          <TextField
            sx={{
              width: 240,
            }}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FaSearch />
                </InputAdornment>
              ),
            }}
            label="Filter columns"
          />
          <TableOverview
            headers={["Column", "Type", "Description", "Tags"]}
            rows={[
              {
                column: "column",
                type: "type",
                description: "description",
                tags: "tag",
              },
              {
                column: "column 2",
                type: "type 2",
                description: "description 2",
                tags: "tag 2",
              },
            ]}
            width={"150px"}
            renderRow={(user, index) => (
              <tr key={index}>
                <TableDataCell>{user.column}</TableDataCell>
                <TableDataCell>{user.type}</TableDataCell>
                <TableDataCell>{user.description}</TableDataCell>
                <TableDataCell>{user.tags}</TableDataCell>
              </tr>
            )}
          />
        </div>
      ),
    },
    "Sample Data": {
      title: "Sample Data",
      component: (
        <TableOverview
          headers={[
            "Id",
            "CreationTime",
            "CreatorUserId",
            "LastModificationTime",
            "LastModifierUserId",
          ]}
          rows={mockedData}
          width={"150px"}
          renderRow={(user, index) => (
            <tr key={index}>
              <TableDataCell>{user.id}</TableDataCell>
              <TableDataCell>{user.creationTime}</TableDataCell>
              <TableDataCell>{user.creatorUserId}</TableDataCell>
              <TableDataCell>{user.lastModificationTime}</TableDataCell>
              <TableDataCell>{user.lastModifierUserId}</TableDataCell>
            </tr>
          )}
        />
      ),
    },
    Permissions: {
      title: "Permissions",
      component: (
        <div>
          <h1>Permissions</h1>
        </div>
      ),
    },
  };

  return (
    <StyledPortalContent>
      <TabsContainer>
        <Tabs tabs={tabData} />
      </TabsContainer>
      <PageContentContainer>
        <h3>{`${databaseName}.${tableName}`}</h3>
        <DescriptionContainer>
          <p>Description:</p>
          <TextField
            size="small"
            variant="outlined"
            sx={{
              width: "70%",
            }}
          />
        </DescriptionContainer>
        <TagContainer>
          <p>Tags:</p>
          <FormControl sx={{ minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Add</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={tag}
              label="Add"
              onChange={(e) => setTag(e.target.value)}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"Public"}>Public</MenuItem>
              <MenuItem value={"Internal"}>Internal</MenuItem>
              <MenuItem value={"Confidential"}>Confidential</MenuItem>
              <MenuItem value={"PII"}>PII</MenuItem>
            </Select>
          </FormControl>
        </TagContainer>
        <p>Owner: email@domain.com</p>
        <TablesTabs>
          {Object.entries(tables).map(([key, value]) => {
            return (
              <h4
                style={{
                  cursor: "pointer",
                  borderBottom: "4px solid",
                  borderBottomColor:
                    key === selectedTable
                      ? Theme.colors.blueColor
                      : "transparent",
                  color:
                    key === selectedTable
                      ? Theme.colors.primaryColor
                      : Theme.colors.disabledColor,
                  "&:hover": "red",
                }}
                onClick={() => setSelectedTable(key)}
              >
                {value.title}
              </h4>
            );
          })}
        </TablesTabs>
        {tables[selectedTable].component}
      </PageContentContainer>
    </StyledPortalContent>
  );
};
