import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";
import Theme from "../Theme";
import databoatLogo from "../../images/databoat.png";
import { Flex } from "../index";
import Button from "../reusableComponents/Button";
import Search from "../reusableComponents/Search";

const StyledDocHeader = styled.div`
  padding: 1em 1.5em;
  background-color: ${({ theme }) => theme.colors.refPrimary20};
`;

export const StyledDocContentFlex = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  gap: ${(props) => (props.gap ? "3em" : "1.5em")};
  justify-content: ${(props) =>
    props.justifyContent ? "space-between" : null};
  /* flex-wrap: wrap; */
  h2 {
    text-decoration-line: none;
    font-weight: ${({ theme }) => theme.fonts.mediumFont};
    font-size: 3vh;
  }
`;


const DocumentHeader = () => {

  return (
    <StyledDocHeader>
      <StyledDocContentFlex justifyContent>
        <Flex>
          <Link to="/docs">
            <img src={databoatLogo} alt="Data boat logo" />
          </Link>
          <Search />
        </Flex>
        
        <Flex>
          <Link to="/">
            <Button
              text="Sign in"
              style={{ backgroundColor: "transparent", color: Theme.colors.whiteColor }}
              className={"next_btn save_btn"}
            />
          </Link>

          <Link to="https://databoat.nl/">
            <Button
              text="Sign up"
              style={{ backgroundColor: Theme.colors.whiteColor , color: Theme.colors.primaryColor }}
              className={"next_btn save_btn"}
            />
          </Link>
        </Flex>
      </StyledDocContentFlex>
    </StyledDocHeader>
  );
};

export default DocumentHeader;
