import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import DocumentPageLayout from '../DocumentPageLayout';

const ManualUpload = () => {
  const { t } = useTranslation();
  const breadcrumbs = [
    { 
      name: t("general.manual_upload"),
      link: '#manual-upload',
    }
  ];

  return (
    <DocumentPageLayout breadcrumbs={breadcrumbs}>
      <Grid container rowSpacing={4} style={{ padding: '0 20px' }}>
        <Grid item>
          <h1 id="manual-upload" style={{ paddingBottom: '20px' }}>{t("general.manual_upload")}</h1>
        </Grid>
      </Grid>
    </DocumentPageLayout>
  );
};

export default ManualUpload;