import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Checkbox, FormControlLabel, FormGroup, Menu } from "@mui/material";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useGetPoolData } from "../../../clientRequest/env-variable";
import {
  CreateJobButton,
  DeleteSvg,
  FilteredBox,
  Flex,
  HomeSvg,
  JobName,
  JobsSvg,
  PlaySvg,
  PreLoader,
  Search,
  StyledDashboardContentFlex,
  StyledPortalContent,
  TableDataCell,
  TableOverview,
  Tabs,
  UsePaginations,
  WorkFlow,
  DeleteModal,
} from "../../index";
import {
  clearToastError,
  clearToastSuccess,
  deleteJobs,
  fetchJobs,
  setIsJobEditing,
} from "../../redux/actions/usersActions";
import { fetchCustomers } from "../../redux/actions/customerAction";
import useGetToken from "../../cognito/useGetToken";

const YedaflowOverview = () => {
  const { token } = useGetToken();
  const dispatch = useDispatch();
  const getPoolData = useGetPoolData();
  const [currentPage, setCurrentPage] = useState(0);
  const { allCustomers, allCustomersLoader } = useSelector(
    (state) => state.customers,
  );
  // eslint-disable-next-line no-unused-vars
  const [pageSize, setPageSize] = useState(10);
  const {
    getJobs,
    getJobLoader,
    environmentVariables,
    jobError,
    patchJobError,
    postJobSuccess,
    patchJobSuccess,
  } = useSelector((state) => state.users);
  const [searchTerm, setSearchTerm] = useState("");
  const [runDateOrder, setRunDateOrder] = useState("");

  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const jobStatus = ["Succeeded", "OK", "Running", "Pending", "Failed"];
  const [statusFilter, setStatusFilter] = useState({});
  const groupName = localStorage.getItem("group");

  let filteredJobs = [];

  function sortByLastRunDate(arr) {
    return arr.sort((a, b) => {
      const dateA = a.last_execution_date
        ? new Date(a.last_execution_date.replace(" ", "T"))
        : null;
      const dateB = b.last_execution_date
        ? new Date(b.last_execution_date.replace(" ", "T"))
        : null;

      if (!dateA && !dateB) {
        return 0; // Both dates are empty, consider them equal
      } else if (!dateA) {
        return 1; // `a` has an empty date, move it to the end
      } else if (!dateB) {
        return -1; // `b` has an empty date, move it to the end
      }

      if (runDateOrder === "asc") {
        return dateA - dateB; // For ascending order
      } else if (runDateOrder === "desc") {
        return dateB - dateA; // For descending order
      } else {
        throw new Error('Order must be either "asc" or "desc"');
      }
    });
  }

  function changeOrder() {
    if (runDateOrder === "") {
      setRunDateOrder("desc");
      return;
    }
    setRunDateOrder((prev) => (prev === "desc" ? "asc" : "desc"));
  }

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(0);
  };

  filteredJobs = getJobs.filter((job) => {
    return job.job_name.toLowerCase().includes(searchTerm.toLowerCase());
  });
  if (runDateOrder !== "") {
    filteredJobs = sortByLastRunDate(filteredJobs);
  }

  if (Object.keys(statusFilter).length > 0) {
    filteredJobs = filteredJobs.filter(
      (job) => statusFilter[job.last_execution_status],
    );
  }
  const endOffset = currentPage + pageSize;
  const currentJobs = filteredJobs.slice(currentPage, endOffset);
  const pageCount = Math.ceil(filteredJobs.length / pageSize);
  const navigate = useNavigate();
  useEffect(() => {
    if (token) {
      dispatch(
        fetchJobs(
          token,
          environmentVariables.REACT_APP_BASE_URL_API_CORE,
          groupName,
        ),
      );
      dispatch(
        fetchCustomers(
          token,
          groupName,
          environmentVariables.REACT_APP_BASE_URL_API_CUSTOMER,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, token]);

  const [isModalOpen, setModalOpen] = useState(false);

  const [selectedjobName, setselectedJobName] = useState(false);

  const openModal = (name) => {
    setselectedJobName(name);
    setModalOpen(true);
  };

  const closeModal = () => setModalOpen(false);

  const handleDeleteActionClick = (selectedjobName) => {
    if (token) {
      dispatch(
        deleteJobs(
          selectedjobName,
          token,
          environmentVariables.REACT_APP_BASE_URL_API_CORE,
        ),
      );
      closeModal();
    }
  };
  function handlePageChange(event) {
    const newOffset = (event.selected * pageSize) % getJobs.length;
    setCurrentPage(newOffset);
  }
  const createdBy = (created) => {
    return created.split("@")[0];
  };
  const fill = "#006399";
  const size = "18";
  const tabData = [
    {
      to: "/dataManagement",
      icon: <HomeSvg fill={fill} width={size} height={size} />,
      label: t("workflow.header_flow.home"),
    },
    {
      to: "/create-job",
      icon: <JobsSvg fill={fill} width={size} height={size} />,
      label: t("workflow.header_flow.jobs"),
    },
    {
      to: "/yedaflow",
      icon: <WorkFlow fill={fill} width={size} height={size} />,
      label: t("workflow.header_flow.management"),
    },
  ];
  useEffect(() => {
    if (postJobSuccess.message) {
      toast.success(t("workflow.table.toast.new_job"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        pauseOnFocusLoss: false,
      });
      dispatch(clearToastSuccess());
    }
    if (jobError) {
      toast.warning(t("workflow.table.toast.job_already"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        pauseOnFocusLoss: false,
      });
      dispatch(clearToastError());
    }
    if (patchJobSuccess.message) {
      toast.success(t("workflow.table.toast.update_sucess"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        pauseOnFocusLoss: false,
      });
      dispatch(clearToastSuccess());
    }
    if (patchJobError) {
      toast.warning(t("workflow.table.toast.update_failed"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        pauseOnFocusLoss: false,
      });
      dispatch(clearToastError());
    }
  }, [
    dispatch,
    jobError,
    patchJobError,
    patchJobSuccess.message,
    postJobSuccess.message,
    t,
  ]);

  async function triggerJob(jobName) {
    const userPool = new CognitoUserPool(getPoolData);
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          throw err;
        } else {
          if (session.isValid()) {
            const token = session.getIdToken().jwtToken;
            try {
              const toastId = toast.loading(
                t("workflow.table.toast.waiting_job_start"),
                {
                  autoClose: true,
                  closeButton: true,
                },
              );
              if (allCustomersLoader) {
                axios
                  .post(
                    `${environmentVariables.REACT_APP_BASE_URL_API_CORE}/yeda/manual-handler?groupName=${groupName}`,
                    {
                      cloudCredential: [
                        {
                          cloudProvider:
                            allCustomers[0].cloudCredential[0].cloudProvider,
                          ssmPathParam:
                            allCustomers[0].cloudCredential[0].ssmPathParam,
                          region: allCustomers[0].cloudCredential[0].region,
                        },
                      ],
                      job_name: jobName,
                      is_new: true,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    },
                  )
                  .then(() => {
                    toast.dismiss(toastId);
                    toast.success(t("workflow.table.toast.job_started"), {
                      pauseOnFocusLoss: false,
                      closeOnClick: true,
                      closeButton: true,
                      autoClose: 5000,
                    });
                    // fetch job update after manual trigger
                    dispatch(
                      fetchJobs(
                        token,
                        environmentVariables.REACT_APP_BASE_URL_API_CORE,
                        groupName,
                      ),
                    );
                  })
                  .catch(() => {
                    toast.dismiss(toastId);
                    toast.error(t("workflow.table.toast.unable_run_job"), {
                      pauseOnFocusLoss: false,
                      closeOnClick: true,
                      closeButton: true,
                      autoClose: 5000,
                    });
                  });
              }
            } catch (error) {
              console.log(error);
            }
          } else {
            cognitoUser.signOut();
            localStorage.clear();
            navigate("/");
          }
        }
      });
    }
  }

  return (
    <StyledPortalContent>
      <ToastContainer />
      {getJobLoader ? (
        <div style={{ padding: "1em", height: "90vh", overflow: "scroll" }}>
          <StyledDashboardContentFlex column>
            <StyledDashboardContentFlex justifyContent>
              <Tabs tabs={tabData} />
              <CreateJobButton
                text={t("workflow.buttons.create_job")}
                type={"button"}
                onClick={() => navigate("/create-job")}
              />
            </StyledDashboardContentFlex>
            <StyledDashboardContentFlex justifyContent>
              <Flex>
                <Search
                  placeholder={t("workflow.search.placeholder")}
                  value={searchTerm}
                  onChange={handleSearch}
                />
                <Flex>
                  {/* <FilteredBox
                    text={t("workflow.filters.start_time")}
                    handleSorting={sortByRunDate}
                  /> */}
                  <FilteredBox
                    text={t("workflow.filters.status")}
                    handleSorting={(e) => {
                      setAnchorEl(e.currentTarget);
                    }}
                    onClick={(e) => {
                      setAnchorEl(e.currentTarget);
                    }}
                  />
                  <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <FormGroup sx={{ pt: 1, pb: 1, pl: 2 }}>
                      {jobStatus.map((el, index) => (
                        <FormControlLabel
                          control={
                            <Checkbox checked={statusFilter[el] ?? false} />
                          }
                          key={index}
                          label={el}
                          onClick={() => {
                            if (!statusFilter[el]) {
                              setStatusFilter((prev) => ({
                                ...prev,
                                [el]: true,
                              }));
                            }

                            if (statusFilter[el]) {
                              setStatusFilter((prev) => {
                                const newObj = { ...prev };
                                delete newObj[el];

                                return newObj;
                              });
                            }
                          }}
                        />
                      ))}
                    </FormGroup>
                  </Menu>
                </Flex>
              </Flex>
            </StyledDashboardContentFlex>
            <Flex column style={{ gap: "2em" }}>
              {/* tables  */}
              <TableOverview
                headers={[
                  "workflow.table.header.name",
                  "workflow.table.header.create_by",
                  "workflow.table.header.run_status",
                  "workflow.table.header.run_date",
                  "workflow.table.header.actions",
                ]}
                rows={currentJobs}
                changeOrder={changeOrder}
                width={"150px"}
                renderRow={(job, index) => (
                  <tr key={index}>
                    <TableDataCell>
                      <JobName
                        onClick={() =>
                          navigate(`/yedaflow/details/${job.job_name}`, {
                            state: job,
                          })
                        }
                      >
                        {job.job_name}
                      </JobName>
                    </TableDataCell>
                    <TableDataCell>
                      {job.created_by
                        ? createdBy(job.created_by)
                        : t("workflow.table.header.data.unknown")}
                    </TableDataCell>
                    <TableDataCell>
                      {job.last_execution_status === ""
                        ? "no status"
                        : job.last_execution_status}
                    </TableDataCell>
                    <TableDataCell>
                      {job.last_execution_date === ""
                        ? "no runs"
                        : moment(job.last_execution_date).format("lll")}
                    </TableDataCell>
                    <TableDataCell style={{ display: "flex", gap: "0.75em" }}>
                      <div
                        onClick={() => {
                          dispatch(setIsJobEditing(true));
                          navigate(`/edit-job/${job.job_name}`);
                        }}
                      >
                        <svg
                          fill="none"
                          cursor={"pointer"}
                          viewBox="0 0 24 24"
                          height="24"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill="#72777F"
                            d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 000-1.41l-2.34-2.34a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
                          ></path>
                        </svg>
                      </div>
                      <PlaySvg onClick={() => triggerJob(job.job_name)} />
                      <DeleteSvg onClick={() => openModal(job.job_name)} />
                    </TableDataCell>
                  </tr>
                )}
              />
              {isModalOpen && (
                <DeleteModal
                  name={"job"}
                  isModalOpen={isModalOpen}
                  closeModal={closeModal}
                  handleDeleteActionClick={() =>
                    handleDeleteActionClick(selectedjobName)
                  }
                />
              )}
              <UsePaginations
                pageCount={pageCount}
                endOffset={endOffset}
                databaseLength={getJobs.length}
                handlePageChange={handlePageChange}
              />
            </Flex>
          </StyledDashboardContentFlex>
        </div>
      ) : (
        <PreLoader />
      )}
    </StyledPortalContent>
  );
};

export default YedaflowOverview;