import { useEffect, useRef, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import FolderIcon from "@mui/icons-material/Folder";
import Theme from "../../../Theme";
import {
  deleteUserScripts,
  fetchUserScripts,
  clearDeleteUserScriptStatusToast,
} from "../../../redux/actions/customerAction";
import {
  DataBaseSideBar,
  HeaderTabButton,
  StyledTreeItem,
} from "../Styles/TransformationStyle";
import {
  PiDatabaseLight,
  PiFilmScriptThin,
  PiCheckBold,
  PiArrowUpBold,
  PiArrowDownBold,
  PiCaretRightLight,
  PiCaretLeftLight,
} from "react-icons/pi";
import ChipInputLeadingIconLabel from "../../../reusableComponents/UIComponents/ChipInputLeadingIconLabel";
import ChipInputLabelTrailingIcon from "../../../reusableComponents/UIComponents/ChipInputLabelTrailingIcon";
import DatabaseList from "./DatabaseList";
import IconTonalButton from "../../../reusableComponents/UIComponents/IconTonalButton";
import IconStandardButton from "../../../reusableComponents/UIComponents/IconStandardButton";
import Search from "../../../reusableComponents/Search";
import { Flex, PreLoader } from "../../..";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { TreeItem, SimpleTreeView } from "@mui/x-tree-view";
import DeleteFolderModal from "../DeleteFolderModal";
import { useSelector, useDispatch } from "react-redux";
import useGetToken from "../../../cognito/useGetToken";

const DbSideBar = ({
  getColumns,
  openScriptTab,
  selectedSQLTab,
  isDbSideBarOpen,
  setIsDbSideBarOpen,
  bundleRef,
}) => {
  const [tabSelect, setTabSelect] = useState("Catalog");
  const [isAllScriptsSelected, setIsAllScriptsSelected] = useState(false);
  const dispatch = useDispatch();
  const { environmentVariables } = useSelector((state) => state.users);

  const user = localStorage.getItem("created");
  const groupName = localStorage.getItem("group");
  const { userScript, userScriptLoader, addUserScript, deleteUserScript } =
    useSelector((state) => state.customers);
  const { token } = useGetToken();
  const { metaDataTablesLoader } = useSelector((state) => state.metaData);
  const [isNameSortSelected, setIsNameSortSelected] = useState(false);
  const [searchScripts, setSearchScripts] = useState("");

  const { t } = useTranslation();
  const handleSearchScripts = (e) => {
    const searchTerm = e.target.value;
    setSearchScripts(searchTerm);
  };
  const script = useRef(null);
  const folder = useRef(null);
  const [open, setOpen] = useState(false);

  const handleClickOpen = (scriptName, item) => {
    folder.current = item;
    script.current = scriptName;
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (token) {
      dispatch(
        fetchUserScripts(
          token,
          groupName,
          user,
          environmentVariables.REACT_APP_BASE_URL_API_CUSTOMER,
        ),
      );
    }
  }, [token, dispatch]);
  const handleDeleteFolder = async (e) => {
    e.preventDefault();
    if (token) {
      dispatch(
        deleteUserScripts(
          token,
          groupName,
          bundleRef.current,
          user,
          folder.current,
          script.current,
          environmentVariables.REACT_APP_BASE_URL_API_CUSTOMER,
        ),
      );
    }
    handleClose();
  };
  useEffect(() => {
    if (token && addUserScript !== null && addUserScript !== undefined) {
      dispatch(
        fetchUserScripts(
          token,
          groupName,
          user,
          environmentVariables.REACT_APP_BASE_URL_API_CUSTOMER,
        ),
      );
    }
    if (token && deleteUserScript !== null && deleteUserScript !== undefined) {
      dispatch(
        fetchUserScripts(
          token,
          groupName,
          user,
          environmentVariables.REACT_APP_BASE_URL_API_CUSTOMER,
        ),
      );
    }
  }, [token, dispatch, addUserScript, deleteUserScript]);

  return (
    <DataBaseSideBar isOpen={isDbSideBarOpen}>
      {isDbSideBarOpen ? (
        <>
          <aside className="dataBaseOpenSideBarHeader">
            <HeaderTabButton
              onClick={() => {
                setTabSelect("Catalog");
              }}
              selected={tabSelect === "Catalog"}
            >
              <PiDatabaseLight size={24} />
              {t("data_management.transformation.sidebar.header.catalog")}
            </HeaderTabButton>
            <HeaderTabButton
              onClick={() => {
                setTabSelect("Script");
              }}
              selected={tabSelect === "Script"}
            >
              <PiFilmScriptThin size={24} />

              {t("data_management.transformation.sidebar.header.script")}
            </HeaderTabButton>
            <HeaderTabButton selected={false}>
              <PiCaretLeftLight
                size={24}
                onClick={() => {
                  setIsDbSideBarOpen(!isDbSideBarOpen);
                }}
              />
            </HeaderTabButton>
          </aside>
          <div
            style={{
              width: "100%",
              height: "1px",
              background: "#C2C7CF",
            }}
          ></div>
          {tabSelect === "Catalog" ? (
            <div className="dataBaseListContainer">
              {metaDataTablesLoader ? (
                <DatabaseList getColumns={getColumns} />
              ) : (
                <PreLoader />
              )}
            </div>
          ) : (
            <div className="dataBaseListContainer" style={{ padding: "1em" }}>
              <div className="dataBaseOptionsFilter">
                <ChipInputLeadingIconLabel
                  label={t(
                    "data_management.transformation.sidebar.filters.script.all",
                  )}
                  handleOnClick={() => {
                    setIsAllScriptsSelected(!isAllScriptsSelected);
                  }}
                  selected={isAllScriptsSelected}
                  leadingIcon={<PiCheckBold />}
                />
                <ChipInputLabelTrailingIcon
                  label={t(
                    "data_management.transformation.sidebar.filters.script.mine",
                  )}
                  handleOnClick={() => {
                    setIsNameSortSelected(!isNameSortSelected);
                  }}
                  selected={isNameSortSelected}
                />
                <ChipInputLabelTrailingIcon
                  label={t(
                    "data_management.transformation.sidebar.filters.script.date",
                  )}
                  handleOnClick={() => {
                    setIsNameSortSelected(!isNameSortSelected);
                  }}
                  selected={isNameSortSelected}
                  leadingIcon={
                    isNameSortSelected ? <PiArrowUpBold /> : <PiArrowDownBold />
                  }
                />
              </div>
              <Search
                placeholder="Search Scripts"
                value={searchScripts}
                onChange={handleSearchScripts}
                width="100%"
              />
              {userScriptLoader ? (
                <div
                  style={{
                    width: "100%",
                    height: 400,
                    maxWidth: 360,
                    overflow: "auto",
                    display: "flex",
                    gap: "1em",
                    flexDirection: "column",
                  }}
                >
                  {userScript !== null && userScript !== undefined
                    ? userScript.map((item, index) => (
                        <Box key={index}>
                          <SimpleTreeView style={{ background: "transparent" }}>
                            <div>
                              <div>
                                <TreeItem
                                  itemId={item.folderName}
                                  label={
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "0.5em",
                                      }}
                                    >
                                      <FolderIcon
                                        style={{
                                          fill: Theme.colors.blueColor,
                                        }}
                                      />
                                      <span>{item.folderName}</span>
                                    </div>
                                  }
                                >
                                  {item.scripts !== null &&
                                    item.scripts !== undefined &&
                                    item.scripts.map((script, scriptIndex) => (
                                      <StyledTreeItem
                                        itemId={script.scriptName}
                                        key={scriptIndex}
                                        label={
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <span
                                              onClick={() =>
                                                openScriptTab(
                                                  script.script,
                                                  script.scriptName,
                                                  selectedSQLTab.id,
                                                  item.folderName,
                                                )
                                              }
                                            >
                                              {script.scriptName}
                                            </span>
                                            <DeleteIcon
                                              style={{
                                                fill: "red",
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                handleClickOpen(
                                                  script.scriptName,
                                                  item.folderName,
                                                )
                                              }
                                            />
                                          </div>
                                        }
                                      />
                                    ))}
                                </TreeItem>
                              </div>
                            </div>
                          </SimpleTreeView>
                        </Box>
                      ))
                    : []}

                  <Flex
                    column
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  ></Flex>
                </div>
              ) : (
                <PreLoader />
              )}
            </div>
          )}
        </>
      ) : (
        <>
          <div className="dataBaseCloseSideBarContainer">
            <HeaderTabButton selected={false}>
              <PiCaretRightLight
                size={24}
                onClick={() => {
                  setIsDbSideBarOpen(!isDbSideBarOpen);
                }}
              />
            </HeaderTabButton>
            <div className="dataBaseCloseSideBarOptions">
              {tabSelect === "Catalog" ? (
                <>
                  <IconTonalButton
                    icon={<PiDatabaseLight size={24} />}
                    handleOnClick={() => {
                      setIsDbSideBarOpen(true);
                      setTabSelect("Catalog");
                    }}
                  />
                  Catalog
                  <IconStandardButton
                    icon={<PiFilmScriptThin size={24} />}
                    handleOnClick={() => {
                      setIsDbSideBarOpen(true);
                      setTabSelect("Script");
                    }}
                  />
                  Script
                </>
              ) : (
                <>
                  <IconStandardButton
                    icon={<PiDatabaseLight size={24} />}
                    handleOnClick={() => {
                      setIsDbSideBarOpen(true);
                      setTabSelect("Catalog");
                    }}
                  />
                  Catalog
                  <IconTonalButton
                    icon={<PiFilmScriptThin size={24} />}
                    handleOnClick={() => {
                      setIsDbSideBarOpen(true);
                      setTabSelect("Script");
                    }}
                  />
                  Script
                </>
              )}
            </div>
          </div>
        </>
      )}
      {open && (
        <DeleteFolderModal
          open={open}
          handleClose={handleClose}
          handleDeleteFolder={handleDeleteFolder}
          script={script}
        />
      )}
    </DataBaseSideBar>
  );
};

export default DbSideBar;
