/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  fetchMetadataDataset,
  fetchMetadataColumns,
  fetchMetadataTables,
} from "../../redux/actions/metaDataAction";
import {
  fetchJobs,
  clearTransformationQueryResultSuccess,
} from "../../redux/actions/usersActions";
import { fetchCustomers } from "../../redux/actions/customerAction";
import TransformationPresenter from "./TransformationPresenter";
import useQuery from "../hooks/useQuery";
import SchedulerModal from "./SchedulerModal";
import ReactLoading from "react-loading";
import { StyledLoadingScreen } from "./Styles/TransformationStyle";
import Theme from "../../Theme";
import useGetToken from "../../cognito/useGetToken";

const Modes = {
  Regular: "regular",
  DragAndDrop: "drag-and-drop",
};

const TransformationContainer = () => {
  const { token } = useGetToken();
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [existingJob, setExistingJob] = useState("");
  const [newJob, setNewJob] = useState(true);

  const navigate = useNavigate();

  const {
    environmentVariables,
    getCustomers,
    getCustomersLoading,
    postQuerySuccess,
    getQueryResultSuccess,
  } = useSelector((state) => state.users);

  const { isLoadingMetaData } = useSelector((state) => state.glueMetadata);
  const { allCustomers } = useSelector((state) => state.customers);

  const dispatch = useDispatch();
  const { runQuery, getQueryResult, handleExportCSV } = useQuery();
  const groupName = localStorage.getItem("group");
  const [SQLTabError, setSQLTabError] = useState("");

  const [isOpenResults, setIsOpenResults] = useState(false);
  const [isRunEnabled, setisRunEnabled] = useState(false);
  const [SQLTab, setSQLTab] = useState([
    {
      id: 1,
      name: "Query 1",
      query: "",
      saved: false,
      folderName: "",
      update: false,
      runResult: false,
      jobId: null,
      results: null,
      selected: "",
      error: "",
    },
  ]);
  const [selectedSQLTab, setSelectedSQLTab] = useState(SQLTab[0]);
  const [mode, setMode] = useState(Modes.Regular);

  useEffect(() => {
    if (selectedSQLTab && selectedSQLTab.id) {
      const updatedTab = SQLTab.find((tab) => tab.id === selectedSQLTab.id);
      setSelectedSQLTab(updatedTab || null);
    } else {
      setSelectedSQLTab(null);
    }
  }, [SQLTab, selectedSQLTab, postQuerySuccess]);

  useEffect(() => {
    if (token) {
      dispatch(
        fetchJobs(
          token,
          environmentVariables.REACT_APP_BASE_URL_API_CORE,
          groupName,
        ),
      );
      dispatch(
        fetchCustomers(
          token,
          groupName,
          environmentVariables.REACT_APP_BASE_URL_API_CUSTOMER,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, token]);

  useEffect(() => {
    if (environmentVariables && token && groupName) {
      dispatch(
        fetchMetadataDataset(
          token,
          groupName,
          environmentVariables.REACT_APP_BASE_URL_API_METADATA,
        ),
      );

      dispatch(
        fetchMetadataTables(
          token,
          groupName,
          environmentVariables.REACT_APP_BASE_URL_API_METADATA,
        ),
      );
    }
  }, [token, dispatch, environmentVariables]);

  const getColumns = (table_name) => {
    if (environmentVariables && token && groupName) {
      dispatch(
        fetchMetadataColumns(
          table_name,
          token,
          groupName,
          environmentVariables.REACT_APP_BASE_URL_API_METADATA,
        ),
      );
    }
  };
  const handleQueryTabSelect = useCallback(
    (tabId) => {
      const newActiveQuery = SQLTab.find(
        (item) => item !== undefined && item.id === tabId,
      );
      setSelectedSQLTab(newActiveQuery);
    },
    [SQLTab],
  );

  const handleAddNewQueryTab = () => {
    const id = SQLTab.length + 1;
    const tabObj = {
      id,
      name: `Query ${id}`,
      query: "",
      saved: false,
      folderName: "",
      update: false,
      runResult: false,
      results: null,
      jobId: null,
      selected: "",
    };
    setSQLTab([...SQLTab, tabObj]);
  };

  const handleUpdateQueryValue = (id, value, selection) => {
    const index = SQLTab.findIndex((item) => item.id === id);
    if (index !== -1) {
      setSQLTabError("");
      const updatedSQLTab = [...SQLTab];
      updatedSQLTab[index].query = value;
      updatedSQLTab[index].selected = selection;
      updatedSQLTab[index].runResult = true;
      setSQLTab(updatedSQLTab);
    }
  };
  const openScriptTab = (query, name, id, folderName) => {
    const index = SQLTab.findIndex((item) => item.id === id);

    if (index !== -1) {
      const updatedSQLTab = [...SQLTab];
      updatedSQLTab[index].query = query;
      updatedSQLTab[index].name = name;
      updatedSQLTab[index].folderName = folderName;
      updatedSQLTab[index].update = true;
      updatedSQLTab[index].runResult = true;
      setSQLTab(updatedSQLTab);
    }
  };
  const countSQLQueries = (query) => {
    return (
      query.match(/(?:select|create|update|delete|insert|drop)\s/gi) || []
    ).length;
  };

  const handleRunSQL = (id) => {
    dispatch(clearTransformationQueryResultSuccess());
    const index = SQLTab.findIndex((item) => item.id === id);
    const queryCount = countSQLQueries(selectedSQLTab.query);
    const queryValue = selectedSQLTab.query;
    const selectedQueryValue = selectedSQLTab.selected;
    if (queryCount === 1 || selectedQueryValue !== "") {
      if (allCustomers !== null && mode === Modes.Regular) {
        runQuery(
          allCustomers[0],
          selectedQueryValue !== "" ? selectedQueryValue : queryValue,
          token,
          environmentVariables.REACT_APP_BASE_URL_API_CORE,
        );
      }
    } else {
      if (index !== -1) {
        const updatedSQLTab = [...SQLTab];
        updatedSQLTab[index].error = "Please ensure you have selected a query";
        setSQLTab(updatedSQLTab);
      }
    }

    setIsOpenResults(true);
  };
  const resetQueryTabError = (id) => {
    const index = SQLTab.findIndex((item) => item.id === id);

    if (index !== -1) {
      const updatedSQLTab = [...SQLTab];
      updatedSQLTab[index].error = "";
      setSQLTab(updatedSQLTab);
    }
  };
  useEffect(() => {
    if (selectedSQLTab.jobId !== null && mode === Modes.Regular && token) {
      if (allCustomers !== null) {
        getQueryResult(
          allCustomers[0],
          token,
          environmentVariables.REACT_APP_BASE_URL_API_CORE,
          selectedSQLTab.jobId,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedSQLTab.jobId,
    allCustomers,
    environmentVariables.REACT_APP_BASE_URL_API_CORE,
    token,
    mode,
  ]);

  useEffect(() => {
    if (postQuerySuccess.jobId !== undefined && mode === Modes.Regular) {
      const index = SQLTab.findIndex((item) => item.id === selectedSQLTab.id);
      if (index !== -1) {
        const updatedSQLTab = [...SQLTab];
        updatedSQLTab[index].jobId = postQuerySuccess.jobId;
        setSQLTab(updatedSQLTab);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postQuerySuccess, mode]);

  useEffect(() => {
    if (
      selectedSQLTab.jobId !== null &&
      selectedSQLTab.jobId === postQuerySuccess.jobId &&
      mode === Modes.Regular
    ) {
      const index = SQLTab.findIndex(
        (item) => item.jobId === postQuerySuccess.jobId,
      );
      if (index !== -1) {
        const updatedSQLTab = [...SQLTab];
        updatedSQLTab[index].results = getQueryResultSuccess;
        setSQLTab(updatedSQLTab);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getQueryResultSuccess, postQuerySuccess.jobId, mode]);

  const handleOpenResults = () => {
    setIsOpenResults(!isOpenResults);
  };
  const [open, setOpen] = useState(false);
  const [scriptName, setScriptName] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
    setScriptName(selectedSQLTab.saved ? selectedSQLTab.name : "");
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleScriptNameChange = (e) => {
    e.preventDefault();
    setScriptName(e.target.value);
  };
  const handleScheduleModal = () => {
    setIsScheduleModalOpen((prev) => !prev);
  };

  const proceedButtonDisabled = () => {
    if (newJob === "") {
      return true;
    }

    if (!newJob && existingJob === "") {
      return true;
    }

    return false;
  };

  const proceed = proceedButtonDisabled();
  function formatSqlQuery(query) {
    return query
      .split("\n")
      .map((line) => line.trim())
      .join(" ");
  }
  const proceedOnScheduleModal = () => {
    const queryValue = SQLTab[0].query;
    const value = formatSqlQuery(queryValue);
    if (newJob) {
      navigate(
        `/create-job?taskName=${scriptName}&taskType=transformation&script=${value}&sourceType=${"sql"}`,
      );
    }

    if (!newJob && existingJob !== "") {
      navigate(
        `/edit-job/${existingJob}?taskName=${scriptName}&taskType=transformation&script=${value}`,
      );
    }
  };
  useEffect(() => {
    dispatch(clearTransformationQueryResultSuccess());
  }, [dispatch]);
  return (
    <>
      <ToastContainer />
      <TransformationPresenter
        getColumns={getColumns}
        selectedSQLTab={selectedSQLTab}
        openScriptTab={openScriptTab}
        SQLTab={SQLTab}
        SQLTabError={SQLTabError}
        setSQLTab={setSQLTab}
        handleQueryTabSelect={handleQueryTabSelect}
        handleAddNewQueryTab={handleAddNewQueryTab}
        handleUpdateQueryValue={handleUpdateQueryValue}
        isOpenResults={isOpenResults}
        handleOpenResults={handleOpenResults}
        scriptName={scriptName}
        handleScriptNameChange={handleScriptNameChange}
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        handleRunSQL={handleRunSQL}
        resetQueryTabError={resetQueryTabError}
        handleScheduleModal={handleScheduleModal}
        isRunEnabled={isRunEnabled}
        handleExportCSV={handleExportCSV}
        mode={mode}
        setMode={setMode}
      />
      {isScheduleModalOpen && (
        <SchedulerModal
          isScheduleModalOpen={isScheduleModalOpen}
          setIsScheduleModalOpen={setIsScheduleModalOpen}
          newJob={newJob}
          setNewJob={setNewJob}
          existingJob={existingJob}
          proceed={proceed}
          proceedOnScheduleModal={proceedOnScheduleModal}
          setExistingJob={setExistingJob}
        />
      )}

      {isLoadingMetaData && (
        <StyledLoadingScreen>
          <ReactLoading
            type={"spin"}
            color={Theme.colors.blueColor}
            height={50}
            width={50}
          />
        </StyledLoadingScreen>
      )}
    </>
  );
};

export default TransformationContainer;
