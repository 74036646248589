import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import DocumentPageLayout from '../DocumentPageLayout';

const ViewingJobs = () => {
  const { t } = useTranslation();
  const breadcrumbs = [
    { 
      name: t("general.viewing_jobs"),
      link: '#viewing-jobs',
    }
  ];

  return (
    <DocumentPageLayout breadcrumbs={breadcrumbs}>
      <Grid container rowSpacing={4} style={{ padding: '0 20px' }}>
        <Grid item>
          <h1 id="viewing-jobs" style={{ paddingBottom: '20px' }}>{t("general.viewing_jobs")}</h1>
        </Grid>
      </Grid>
    </DocumentPageLayout>
  );
};

export default ViewingJobs;