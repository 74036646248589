import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, ListItemButton, ListItemText } from '@mui/material';
import { LinkableImageCard } from '../dashboardContent/Card';
import styled from 'styled-components';
import DocumentPageLayout from './DocumentPageLayout';

import NextInsightImage from "../../images/Next insights.jpg";
import SchemaAndOwnershipImage from "../../images/Schema & Ownership.jpg";
import SteeringWheel from "../../images/boat-steering.png";
import IngestDataImage from "../../images/ingest Data.jpg";
import BoatGptImage from "../../images/boat_GPT.png";

const MainGrid = styled.div`        
  #no-scrollbg::-webkit-scrollbar {
    width: auto;
  }
  #no-scrollbg::-webkit-scrollbar-track, #no-scrollbg::-webkit-scrollbar-thumb {
    background: transparent;
  }
`;

const Home = () => {
  const { t } = useTranslation();
  const breadcrumbs = [
    { 
      name: t("general.documentation"),
      link: '#documentation',
    },
    { 
      name: t("general.data_onboarding"),
      link: '#data-onboarding',
    },
    { 
      name: t("general.workflow_management"),
      link: '#workflow-management',
    },
    { 
      name: t("general.data_transformation"),
      link: '#data-transformation',
    },
  ];

  const displayImageGrid = () => {
    // samle images w/ content
    const imageItems = [
      {
        image: BoatGptImage,
        title: t("general.quickstart"),
        body: "DataBoat is an advanced data platform designed to streamline data management processes, offering a comprehensive suite of capabilities tailored to meet the evolving needs of modern data-driven enterprises.",
        url: "overview"
      },
      {
        image: BoatGptImage,
        title: t("general.setup_a_job"),
        body: "DataBoat is an advanced data platform designed to streamline data management processes, offering a comprehensive suite of capabilities tailored to meet the evolving needs of modern data-driven enterprises.",
        url: "overview"
      },
      {
        image: BoatGptImage,
        title: t("general.ingest_using_connectors"),
        body: "DataBoat is an advanced data platform designed to streamline data management processes, offering a comprehensive suite of capabilities tailored to meet the evolving needs of modern data-driven enterprises.",
        url: "overview"
      },
    ] 
    return (
      <Grid container spacing={2} columns={9}>
        {imageItems.map((item) => (
          <Grid item xs={12} sm={6} md={3} sx={{ p: 0 }}>
            <LinkableImageCard 
              {...item}
              url={`/docs/${item.url}`}
            />
          </Grid>
        ))}
      </Grid>
    );
  };


  return (
    <DocumentPageLayout breadcrumbs={breadcrumbs}>
      <Grid container rowSpacing={4} style={{ padding: '0 20px' }}>
        <Grid item>
          <h1 id="documentation" style={{ paddingBottom: '20px' }}>{t("general.documentation")}</h1>
          <p>Welcome to the DataBoat Knowledge Base. Guidance and resources for who are addressing in new challenges, techniques and tools for growth in data analytics and AI. All information you need to accelerate your boat</p>
        </Grid>

        <Grid item>
          {displayImageGrid()}
        </Grid>
        
        <Grid item>
          <h2 id="data-onboarding" style={{ paddingBottom: '20px' }}>{t("general.data_onboarding")}</h2>
          <p>Seamlessly synchronize data across a diverse range of systems and platforms with DataBoat's adaptable data synchronization capabilities. Featuring plug-ins for connecting to multiple systems including databases, social media platforms, SaaS applications, ERPs, and Salesforce, DataBoat ensures seamless data integration and consistency, facilitating streamlined data management across the enterprise.</p>
        </Grid>

        <Grid item>
          <h2 id="workflow-management" style={{ paddingBottom: '20px' }}>{t("general.workflow_management")}</h2>
          <p>Simplify the orchestration of data workflows and automate routine tasks with DataBoat's robust job scheduler management capabilities. From scheduling data ingestion to transformation and synchronization tasks, DataBoat offers flexible scheduling options and comprehensive monitoring tools to optimize workflow management and boost operational efficiency.</p>
        </Grid>

        <Grid item>
          <h2 id="data-transformation" style={{ paddingBottom: '20px' }}>{t("general.data_transformation")}</h2>
          <p>Empower users to unlock the full potential of their data with DataBoat's versatile data transformation features. From traditional SQL queries to intuitive drag-and-drop interfaces and advanced AI-powered query generation capabilities, DataBoat offers flexible tools for data preparation, cleansing, and transformation, enabling users to derive actionable insights effortlessly.</p>
        </Grid>

      </Grid>
    </DocumentPageLayout>
  );
};

export default Home;