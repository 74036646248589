import React, { useState } from "react";
import { TextField } from "@mui/material";

import { StyledDashboardContentFlex, RenderDataItems } from "../../../index";
import { ReusableFieldSet } from "../../../reusableComponents/FieldSet";
import { useTranslation } from "react-i18next";

const BigQueryConnector = ({
  bigQueryData,
  credential,
  credentialError,
  handleBigQueryValueChange,
  handleCredentialJsonChange,
  onClose,
  bqTables,
  bigQueryTableHead,
  addBqTablesRow,
  taskId,
  handleProjectIdChange,
  datasets,
  handleDataSetIdChange,
  allTables,
  handleTableIdChange,
  isTableId,
  handleBigQueryLoadTypeChange,
  allDeltaColumn,
  handleBigQueryDeltaColumnChange,
  handleBigQueryPartionChange,
  allPartitionColumn,
  handleBigQueryPartitioningChange,
  handleOutputTableName,
}) => {
  const [isCredentialsFocused, setIsCredentialsFocused] = useState(false);
  const tableOptions = [
    { id: 1, option: "", title: "Select" },
    { id: 2, option: "full", title: "Full" },
    { id: 3, option: "delta", title: "Delta" },
  ];
  const style = {
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "&:hover .MuiInput-underline:before": {
      borderBottom: "none",
    },
  };

  const { t } = useTranslation();

  return (
    <StyledDashboardContentFlex column style={{ padding: "2em" }}>
      <p>{t("jobs.form.inputs.task.modal.connectors.big_query.title")}</p>
      <ReusableFieldSet
        title={t(
          "jobs.form.inputs.task.modal.connectors.big_query.inputs.credentials",
        )}
        isFocus={isCredentialsFocused}
        children={
          <textarea
            defaultValue=""
            onChange={handleCredentialJsonChange}
            name="message"
            id="Credentials Json"
            rows={4}
            cols={40}
            onFocus={() => setIsCredentialsFocused(true)}
            onBlur={() => setIsCredentialsFocused(false)}
            className={"signin_input_focus, paddingClass"}
          />
        }
      />
      {credentialError && <p className="error">{credentialError}</p>}
      <RenderDataItems
        data={bigQueryData}
        handleChange={handleBigQueryValueChange}
      />
      <div
        className="tableContainer"
        style={{ display: credential === "" && "none" }}
      >
        <table>
          <thead>
            <tr>
              {bigQueryTableHead.map((column, index) => (
                <th key={index} style={{ fontSize: "0.75em" }}>
                  {column.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {bqTables.map((data) => (
              <tr key={data.id}>
                <td style={{ width: "300px" }}>
                  <TextField
                    id={data.name}
                    variant="standard"
                    sx={style}
                    onChange={(event) =>
                      handleProjectIdChange(event, data.id, taskId)
                    }
                  />
                </td>

                <td style={{ width: "300px" }}>
                  <select
                    defaultValue={""}
                    onChange={(event) =>
                      handleDataSetIdChange(event, data.id, taskId)
                    }
                    style={{ width: "100%", padding: "0.75em" }}
                    name="select ingestion Mode"
                  >
                    <option value="">--select</option>
                    {datasets.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </td>
                <td style={{ width: "300px" }}>
                  <select
                    defaultValue={""}
                    onChange={(event) =>
                      handleTableIdChange(event, data.id, taskId)
                    }
                    style={{ width: "100%", padding: "0.75em" }}
                    name="select ingestion Mode"
                  >
                    <option value="">--select</option>
                    {allTables.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </td>
                <td
                  style={{
                    width: "300px",
                  }}
                >
                  <select
                    defaultValue={""}
                    onChange={(event) =>
                      handleBigQueryLoadTypeChange(event, data.id, taskId)
                    }
                    style={{ width: "100%", padding: "0.75em" }}
                    name="select ingestion Mode"
                  >
                    {tableOptions.map((item) => (
                      <option key={item.id} value={item.option}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                </td>
                {data.load_type === "delta" && (
                  <td
                    style={{
                      width: "300px",
                    }}
                  >
                    <select
                      defaultValue={""}
                      onChange={(event) =>
                        handleBigQueryDeltaColumnChange(event, data.id, taskId)
                      }
                      style={{ width: "100%", padding: "0.75em" }}
                      name="select ingestion Mode"
                    >
                      <option value="">--select</option>
                      {allDeltaColumn.map((item) => (
                        <option key={item.name} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </td>
                )}
                <td
                  style={{
                    width: "300px",
                  }}
                >
                  <input
                    type="checkbox"
                    defaultChecked={false}
                    onChange={(event) =>
                      handleBigQueryPartionChange(event, data.id, taskId)
                    }
                  />
                </td>
                {data.partitioning && (
                  <td
                    style={{
                      width: "300px",
                    }}
                  >
                    <select
                      defaultValue={""}
                      onChange={(event) =>
                        handleBigQueryPartitioningChange(event, data.id, taskId)
                      }
                      style={{ width: "100%", padding: "0.75em" }}
                      name="select ingestion Mode"
                    >
                      <option value="">--select</option>
                      {allPartitionColumn.map((item) => (
                        <option key={item.name} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </td>
                )}
                {isTableId && (
                  <td style={{ width: "300px" }}>
                    <TextField
                      id={data.name}
                      variant="standard"
                      sx={style}
                      onChange={(event) =>
                        handleOutputTableName(event, data.id, taskId)
                      }
                    />
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        <div>
          <button onClick={addBqTablesRow} className={"next_btn save_btn"}>
            Add more Tables
          </button>
        </div>
      </div>
      <div>
        <button className={"next_btn save_btn"} onClick={onClose}>
          {t("jobs.form.inputs.task.modal.button")}
        </button>
      </div>
    </StyledDashboardContentFlex>
  );
};

export default BigQueryConnector;
