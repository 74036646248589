import { axiosGetJobs } from "../../../clientRequest/axiosRequest";

export const fetchMetadataDataset =
  (token, group, baseURL) => async (dispatch) => {
    try {
      dispatch({ type: "GET_METADATA_DATASET_LOADING" });

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      // Fetch datasets
      const res = await axiosGetJobs(baseURL).get(`/datasets?group=${group}`, {
        headers,
      });
      const datasets = res.data;

      dispatch({ type: "GET_METADATA_DATASET_SUCCESS", payload: datasets });

      // // Fetch tables for each dataset in parallel
      // const tablePromises = datasets.map((element) => {
      //   return fetchMetadataTables(element, token, group, baseURL)(dispatch);
      // });

      // // Wait for all fetches to complete
      // const allTablesResults = await Promise.all(tablePromises);

      // // Flatten the array of arrays
      // const allTables = allTablesResults.flat();
    } catch (error) {
      dispatch({ type: "GET_METADATA_DATASET_ERROR", payload: error });
    }
  };
export const fetchMetadataTables =
  (token, group, baseURL) => async (dispatch) => {
    try {
      dispatch({ type: "GET_METADATA_TABLE_LOADING" });

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const res = await axiosGetJobs(baseURL).get(`/tables?group=${group}`, {
        headers,
      });
      const tables = res.data;

      dispatch({ type: "GET_METADATA_TABLE_SUCCESS", payload: tables });

      return tables; // Return the result for aggregation
    } catch (error) {
      dispatch({ type: "GET_METADATA_TABLE_ERROR", payload: error });
      return []; // Return an empty array on error
    }
  };

export const fetchMetadataColumns =
  (tableName, token, group, url) => async (dispatch) => {
    try {
      dispatch({
        type: "GET_METADATA_COLUMN_LOADING",
      });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const res = await axiosGetJobs(url).get(
        `columns?table_name=${tableName}&group=${group}`,
        {
          headers,
        },
      );
      dispatch({
        type: "GET_METADATA_COLUMN_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "GET_METADATA_COLUMN_ERROR",
        payload: error,
      });
    }
  };
export const updateMetadataTableDescription =
  (formData, token, group, url) => async (dispatch) => {
    try {
      dispatch({
        type: "PATCH_METADATA_TABLE_LOADING",
      });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const res = await axiosGetJobs(url).patch(
        `/tableDescription?group=${group}`,
        formData,
        {
          headers,
        },
      );
      dispatch({
        type: "PATCH_METADATA_TABLE_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "PATCH_METADATA_TABLE_ERROR",
        payload: error,
      });
    }
  };
export const fetchMetadataTablesOnClick =
  (datasetName, token, group, url) => async (dispatch) => {
    try {
      dispatch({ type: "GET_METADATA_TABLE_ONCLICK_LOADING" });

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const res = await axiosGetJobs(url).get(
        `/tables?dataset_name=${datasetName}&group=${group}`,
        { headers },
      );
      const tables = res.data;

      dispatch({ type: "GET_METADATA_TABLE_ONCLICK_SUCCESS", payload: tables });

      return tables; // Return the result for aggregation
    } catch (error) {
      dispatch({ type: "GET_METADATA_TABLE_ONCLICK_ERROR", payload: error });
      return []; // Return an empty array on error
    }
  };
