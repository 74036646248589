import React, { useState, useEffect, useRef } from 'react';
import { useLocation  } from 'react-router-dom';
import { Box, Grid, ListItemButton, ListItemText } from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Theme from '../Theme';

const MainGrid = styled.div`        
  #no-scrollbg::-webkit-scrollbar {
    width: auto;
  }
  #no-scrollbg::-webkit-scrollbar-track, #no-scrollbg::-webkit-scrollbar-thumb {
    background: transparent;
  }
`;

const DocumentPageLayout = ({
  children,
  breadcrumbs,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const bodyRef = useRef();

  useEffect(() => {
    // Scroll to the element with the ID from the fragment identifier
    if (location.hash) {
      const element = document.querySelector(location.hash)
      if (element) {
        element.scrollIntoView({ block: "end", behavior: 'smooth' })
      }
    }
  }, [location.hash])

  const displayMainContent = () => {
    return (
      <Grid 
        item 
        xs={9.5} 
        md={9.5} 
        sx={{ 
          maxHeight: 'calc(100vh - 120px)', 
          overflowY: 'scroll',             
          '::-webkit-scrollbar': { width: 'auto' },
          '::-webkit-scrollbar-track': { background: 'transparent' },
          '::-webkit-scrollbar-thumb': { background: 'transparent' },
        }}
      >
        {children || ''} 
      </Grid>
    )
  }

  const displayDocumentBreadcrumbs = () => {
    return (
      <Grid item xs={2.5} md={2.5}>
        <p style={{ fontWeight: 'bold', margin: '20px 0 10px' }}>{t("general.on_this_page")}</p>
        <Box sx={{ flexGrow: 1, borderLeft: `solid 2px ${Theme.colors.refPrimary20}` }}>
          {((breadcrumbs || []).map((crumb, idx) => (
            <ListItemButton 
              href={crumb.link ||"#"} 
              key={idx} 
            >
              <ListItemText primary={crumb.name} />
            </ListItemButton>
          )))}
        </Box>

        <p style={{ fontWeight: 'bold', margin: '20px 0 10px' }}>{t("general.more_from_databoat")}</p>
        <ListItemButton href="/contact-us">
          <ListItemText primary={t("general.contact_us")} />
        </ListItemButton>
      </Grid>
    );
  }

  return (
    <Box
      sx={{ 
        flexGrow: 1,
        bgcolor: 'background.paper', 
        display: 'flex', 
        height: '100%' 
      }}
      ref={bodyRef}
    >
      <Grid container columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        {displayMainContent()}
        {displayDocumentBreadcrumbs()}
      </Grid>
    </Box>
  );
};

export default DocumentPageLayout;