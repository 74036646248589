const initialState = {
  metaDataDataset: null,
  metaDataDatasetLoader: false,
  metaDataDatasetError: null,
  metaDataTables: null,
  metaDataTablesLoader: false,
  metaDataTablesError: null,
  metaDataColumns: null,
  metaDataColumnsLoader: false,
  metaDataColumnsError: null,
  getTablesOnclick: null,
  getTablesOnclickLoader: false,
  getTablesOnclickError: null,
};
const metadataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_METADATA_DATASET_LOADING":
      return {
        ...state,
        metaDataDatasetLoader: false,
      };
    case "GET_METADATA_DATASET_SUCCESS":
      return {
        ...state,
        metaDataDataset: action.payload,
        metaDataDatasetLoader: true,
      };
    case "GET_METADATA_DATASET_ERROR":
      return {
        ...state,
        metaDataDatasetLoader: false,
        metaDataDatasetError: action.payload,
      };

    case "GET_METADATA_TABLE_LOADING":
      return {
        ...state,
        metaDataTablesLoader: false,
      };
    case "GET_METADATA_TABLE_SUCCESS":
      return {
        ...state,
        metaDataTables: action.payload,
        metaDataTablesLoader: true,
      };
    case "GET_METADATA_TABLE_ERROR":
      return {
        ...state,
        metaDataTablesLoader: false,
        metaDataTablesError: action.payload,
      };

    case "GET_METADATA_COLUMN_LOADING":
      return {
        ...state,
        metaDataColumnsLoader: false,
      };
    case "GET_METADATA_COLUMN_SUCCESS":
      return {
        ...state,
        metaDataColumns: action.payload,
        metaDataColumnsLoader: true,
      };
    case "GET_METADATA_COLUMN_ERROR":
      return {
        ...state,
        metaDataColumnsLoader: false,
        metaDataColumnsError: action.payload,
      };
    case "GET_METADATA_TABLE_ONCLICK_LOADING":
      return {
        ...state,
        getTablesOnclickLoader: false,
      };
    case "GET_METADATA_TABLE_ONCLICK_SUCCESS":
      return {
        ...state,
        getTablesOnclick: action.payload,
        getTablesOnclickLoader: true,
      };
    case "GET_METADATA_TABLE_ONCLICK_ERROR":
      return {
        ...state,
        getTablesOnclickLoader: false,
        getTablesOnclickError: action.payload,
      };

    default:
      return state;
  }
};

export default metadataReducer;
