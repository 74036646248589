/* eslint-disable */
import React, { useEffect, useRef, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdClose } from "react-icons/md";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useNavigate, useParams } from "react-router-dom";
import { axiosGetJobs } from "../../../clientRequest/axiosRequest";
import PreLoader from "../../reusableComponents/PreLoader";
import { StyledDashboardContentFlex, Flex } from "../../styles/Login.Styled";
import { StyledModal, ModalHeader } from "../../styles/Modal.Styled";
import ReusableDatePicker from "./ReusableDatePicker";
import {
  JobDetailsButton,
  CreateJobButton,
  JobDetailsCheckbox,
  JobDetailsCheckboxContainer,
  JobDetailsCheckmark,
  JobDetailsCustomCheckbox,
  JobDetailsTopHeader,
  JobNameContainer,
  StyledYedaflowContainer,
} from "../../styles/Yedaflow.Styled";
import { setIsJobEditing } from "../../redux/actions/usersActions";
import Breadcrumbs from "./Breadcrumbs";
import JobRunsTable from "./JobRunsTable";
import axios from "axios";
import { toast } from "react-toastify";
import { Dialog, Autocomplete, TextField } from "@mui/material";
import useGetToken from "../../cognito/useGetToken";
import { fetchCustomers } from "../../redux/actions/customerAction";
//JOB DETAILS
function deepEqual(x, y) {
  const ok = Object.keys,
    tx = typeof x,
    ty = typeof y;
  return x && y && tx === "object" && tx === ty
    ? ok(x).length === ok(y).length &&
        ok(x).every((key) => deepEqual(x[key], y[key]))
    : x === y;
}

const JobDetailsOverview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { jobName } = useParams();
  const jobNameRef = useRef(jobName);
  const [runs, setRuns] = useState([]);
  const [job, setJob] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [logs, setLogs] = useState("");
  const [currentTask, setCurrentTask] = useState(null);
  const [jobError, setJobError] = useState("");
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [lastRuns, setLastRuns] = useState(null);
  const [isLoadingExecutions, setIsLoadingExecutions] = useState(true);
  const [isLoadingJob, setIsLoadingJob] = useState(true);
  const firstRender = useRef(true);
  const groupName = localStorage.getItem("group");
  const { environmentVariables } = useSelector((state) => state.users);
  const { allCustomers } = useSelector((state) => state.customers);
  const callCount = useRef(0);
  const { token } = useGetToken();
  useEffect(() => {
    if (token) {
      dispatch(
        fetchCustomers(
          token,
          groupName,
          environmentVariables.REACT_APP_BASE_URL_API_CUSTOMER,
        ),
      );
      getExecution(token);
      if (firstRender.current) {
        getJobs(token);
        firstRender.current = false;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, dispatch]);
  const getExecution = async (token) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    try {
      const executionsResponse = await axiosGetJobs(
        environmentVariables.REACT_APP_BASE_URL_API_CORE,
      ).get(`/yeda/execution?jobName=${jobNameRef.current}`, { headers });
      setRuns(executionsResponse.data);
      setLastRuns(executionsResponse.data);

      setIsLoadingExecutions(false);
    } catch (error) {
      if (error) {
        setJobError("Rate Exceeded");
        throw error;
      }
    }
  };
  const getJobs = async (token) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const jobsResponse = await axiosGetJobs(
      environmentVariables.REACT_APP_BASE_URL_API_CORE,
    ).get(`/yeda/jobs?groupName=${groupName}`, { headers });
    const job = jobsResponse.data.find((job) => job.job_name === jobName);
    setJob(job);
    setIsLoadingJob(false);
  };

  useEffect(() => {
    if (lastRuns && !deepEqual(runs, lastRuns)) {
      setRuns(lastRuns);
      setLastRuns(null);
    }
  }, [lastRuns, runs]);

  useEffect(() => {
    if (autoRefresh) {
      const interval = setInterval(() => {
        if (callCount.current < 5) {
          checkSessionExpiration();
          callCount.current += 1;
        } else {
          clearInterval(interval);
        }
      }, 500); // Set interval to 0.5s
      return () => clearInterval(interval);
    }
  }, [autoRefresh]);

  function onTaskClick(task, execution_id, execution_date) {
    const newTask = {
      task: task,
      execution_id: execution_id,
      execution_date: execution_date,
    };

    setCurrentTask(newTask);
    localStorage.setItem("currentTask", JSON.stringify(newTask));
    setModalOpen((prev) => !prev);
    fetchJobLogs(task.task_id);
  }

  async function rerunTask() {
    const toastId = toast.loading("Waiting for task to start running", {
      autoClose: true,
      closeButton: true,
    });

    if (!currentTask) {
      toast.dismiss(toastId);
      toast.error("No task selected", {
        pauseOnFocusLoss: false,
        closeOnClick: true,
        closeButton: true,
        autoClose: 5000,
      });
      return;
    }

    try {
      if (currentTask) {
        const formData = {
          cloudCredential: [
            {
              cloudProvider: allCustomers[0].cloudCredential[0].cloudProvider,
              ssmPathParam: allCustomers[0].cloudCredential[0].ssmPathParam,
              region: allCustomers[0].cloudCredential[0].region,
            },
          ],
          job_name: jobName,
          is_new: false,
          last_execution_date: currentTask.execution_date,
          task_name: currentTask.task.task_name,
          task_id: currentTask.task.task_id,
          execution_id: currentTask.execution_id,
        };
        await axios.post(
          `${environmentVariables.REACT_APP_BASE_URL_API_CORE}/yeda/manual-handler?groupName=${groupName}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        toast.dismiss(toastId);
        toast.success("Task started running", {
          pauseOnFocusLoss: false,
          closeOnClick: true,
          closeButton: true,
          autoClose: 5000,
        });
        if (autoRefresh) {
          return;
        } else {
          navigate(0);
        }
      } else {
        return;
      }
    } catch (error) {
      toast.dismiss(toastId);
      toast.error("Unable to run job right now", {
        pauseOnFocusLoss: false,
        closeOnClick: true,
        closeButton: true,
        autoClose: 5000,
      });
      console.log(error);
    }
  }

  async function fetchJobLogs(taskId) {
    try {
      const logsResponse = await axios.post(
        `${environmentVariables.REACT_APP_BASE_URL_API_CORE}/yeda/logging`,
        {
          cloudCredential: [
            {
              cloudProvider: allCustomers[0].cloudCredential[0].cloudProvider,
              ssmPathParam: allCustomers[0].cloudCredential[0].ssmPathParam,
              region: allCustomers[0].cloudCredential[0].region,
            },
          ],
          task_id: taskId,
        },

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setLogs(logsResponse.data);
    } catch (error) {
      console.log("Error fetching logs:", error);
    }
  }

  async function triggerJob() {
    try {
      const toastId = toast.loading("Waiting for job to start running", {
        autoClose: true,
        closeButton: true,
      });
      axios
        .post(
          `${environmentVariables.REACT_APP_BASE_URL_API_CORE}/yeda/manual-handler?groupName=${groupName}`,
          {
            cloudCredential: [
              {
                cloudProvider: allCustomers[0].cloudCredential[0].cloudProvider,
                ssmPathParam: allCustomers[0].cloudCredential[0].ssmPathParam,
                region: allCustomers[0].cloudCredential[0].region,
              },
            ],
            job_name: jobName,
            is_new: true,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then(() => {
          toast.dismiss(toastId);
          toast.success("Job started running", {
            pauseOnFocusLoss: false,
            closeOnClick: true,
            closeButton: true,
            autoClose: 5000,
          });
          !autoRefresh && setTimeout(navigate(0), 5000);
        })
        .catch(() => {
          toast.dismiss(toastId);
          toast.error("Unable to run task right now", {
            pauseOnFocusLoss: false,
            closeOnClick: true,
            closeButton: true,
            autoClose: 5000,
          });
        });
    } catch (error) {
      console.log(error);
    }
  }
  const handleEditJob = () => {
    dispatch(setIsJobEditing(true));
    navigate(`/edit-job/${jobName}`);
  };

  const copyToClipboard = () => {
    const logsText = logs.join("\n");
    navigator.clipboard
      .writeText(logsText)
      .then(() => {
        toast.success("Logs copied to clipboard", {
          pauseOnFocusLoss: false,
          closeOnClick: true,
          closeButton: true,
          autoClose: 5000,
        });
      })
      .catch((err) => {
        toast.error("Failed to cpoy", {
          pauseOnFocusLoss: false,
          closeOnClick: true,
          closeButton: true,
          autoClose: 5000,
        });
      });
  };
  const [lastItems, setLastItems] = useState(null);
  const [lastIndex, setLastIndex] = useState(null);
  useEffect(() => {
    if (runs) {
      const lastItems = runs.map((execution) => {
        const executionsArray = execution.executions;
        return executionsArray[executionsArray.length - 1];
      });

      const sortedData = [...lastItems].sort(
        (a, b) => new Date(a.execution_date) - new Date(b.execution_date),
      );
      const lastIndex = sortedData.length - 1;
      const lastItem = sortedData[lastIndex];

      setLastIndex(lastItem);
      setLastItems(sortedData);
    }

    const savedTask = localStorage.getItem("currentTask");
    if (savedTask) {
      setCurrentTask(JSON.parse(savedTask));
    }
  }, [runs]);

  const toastShownRef = useRef(false);

  useEffect(() => {
    if (lastIndex && callCount.current === 5 && !toastShownRef.current) {
      if (lastIndex.job_status === "Failed") {
        toast.error(lastIndex.job_status, {
          pauseOnFocusLoss: false,
          closeOnClick: true,
          closeButton: true,
          autoClose: 1000,
        });
      } else if (lastIndex.job_status === "Succeeded") {
        toast.success(lastIndex.job_status, {
          pauseOnFocusLoss: false,
          closeOnClick: true,
          closeButton: true,
          autoClose: 1000,
        });
      }

      // Set toastShownRef to true after showing the toast
      toastShownRef.current = true;
    } else if (
      runs.length == 0 &&
      callCount.current === 5 &&
      !toastShownRef.current
    ) {
      toast.error("Failed", {
        pauseOnFocusLoss: false,
        closeOnClick: true,
        closeButton: true,
        autoClose: 1000,
      });
    }
  }, [lastIndex, callCount, runs]);
  const executions = [
    { label: "15" },
    { label: "20" },
    { label: "38" },
    { label: "50" },
    { label: "100" },
    { label: "120" },
    { label: "150" },
  ];
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);

  const handleDateChange = (newValue) => {
    setSelectedDateRange(newValue);
  };

  useEffect(() => {
    setSelectedValue({ label: "15" });
  }, []);
  const slicedData = useMemo(() => {
    if (!lastItems || !selectedDateRange) return [];

    const [startDate, endDate] = selectedDateRange.map((date) =>
      date ? new Date(date) : null,
    );

    return lastItems.filter((item) => {
      const executionDate = new Date(item.execution_date);
      return (
        (!startDate || executionDate >= startDate) &&
        (!endDate || executionDate <= endDate)
      );
    });
  }, [lastItems, selectedDateRange]);

  // Use useMemo to slice filteredData based on selectedValue
  const filteredData = useMemo(() => {
    if (selectedValue && selectedValue.label) {
      const number = parseInt(selectedValue.label, 10);
      if (!isNaN(number)) {
        return slicedData.slice(0, number);
      }
    }
    return slicedData;
  }, [slicedData, selectedValue]);

  // Handle changes from Autocomplete
  const handleChange = (event, newValue) => {
    setSelectedValue(newValue || { label: "15" });
  };

  return (
    <>
      <StyledYedaflowContainer style={{ width: "100%" }}>
        {isLoadingExecutions || isLoadingJob ? (
          <PreLoader />
        ) : jobError === "" ? (
          <StyledDashboardContentFlex column>
            <JobDetailsTopHeader>
              <Breadcrumbs
                values={[
                  { name: "Jobs", path: "/yedaflow" },
                  { name: jobName, path: null },
                ]}
              ></Breadcrumbs>
              <JobDetailsCheckboxContainer>
                <JobDetailsCheckbox
                  type="checkbox"
                  value={autoRefresh}
                  checked={autoRefresh}
                  onChange={() => setAutoRefresh((prev) => !prev)}
                />
                <JobDetailsCustomCheckbox autoRefresh={autoRefresh}>
                  <JobDetailsCheckmark autoRefresh={autoRefresh} />
                </JobDetailsCustomCheckbox>
                Auto-refresh
              </JobDetailsCheckboxContainer>
            </JobDetailsTopHeader>
            <JobNameContainer>
              <h1>{jobName}</h1>
              <Flex>
                <CreateJobButton
                  onClick={() => triggerJob()}
                  text={"Trigger"}
                />
                <CreateJobButton text={"Edit"} onClick={handleEditJob} />
              </Flex>
            </JobNameContainer>
            <Flex column>
              <Flex>
                <div>
                  <ReusableDatePicker
                    selectedDateRange={selectedDateRange}
                    handleDateChange={handleDateChange}
                    label="Execution date"
                  />
                </div>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  freeSolo
                  options={executions}
                  getOptionLabel={(option) => option.label}
                  value={
                    executions.find(
                      (execution) => execution.label === selectedValue,
                    ) || null
                  }
                  onChange={handleChange}
                  sx={{
                    width: 250,
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "50px",
                    },
                    marginRight: "10px",
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Last Execution" />
                  )}
                />
              </Flex>

              {filteredData.length > 0 ? (
                <JobRunsTable
                  job={job}
                  lastItems={filteredData}
                  handleTaskClick={onTaskClick}
                />
              ) : (
                <p>No records found</p>
              )}
            </Flex>
          </StyledDashboardContentFlex>
        ) : (
          <p>{jobError}</p>
        )}
      </StyledYedaflowContainer>
      {modalOpen && (
        <StyledModal>
          <Dialog
            open={modalOpen}
            onClose={() => setModalOpen((prev) => !prev)}
            fullWidth
            PaperProps={{ sx: { borderRadius: "28px", maxWidth: "60%" } }}
          >
            <ModalHeader>
              <p style={{ fontWeight: "bold", fontSize: "1.5em" }}>
                {currentTask.task.task_name}
              </p>
              <MdClose
                style={{ cursor: "pointer", color: "#fff", fontSize: "2em" }}
                onClick={() => setModalOpen((prev) => !prev)}
              ></MdClose>
            </ModalHeader>
            <StyledDashboardContentFlex column style={{ paddingTop: "1em" }}>
              <div style={{ backgroundColor: "#B9D9EB" }}>
                <StyledDashboardContentFlex
                  justifyContent
                  style={{ padding: "2em" }}
                >
                  <div>
                    {logs.length > 0 &&
                      logs.map((log, index) => <p key={index}>{log}</p>)}
                  </div>
                  <ContentCopyIcon
                    onClick={copyToClipboard}
                    style={{ cursor: "pointer" }}
                  />
                </StyledDashboardContentFlex>
              </div>
              <Flex style={{ flexDirection: "row-reverse", padding: "2em" }}>
                <div>
                  <JobDetailsButton
                    text={"Rerun"}
                    onClick={() => {
                      setModalOpen(false);
                      rerunTask();
                    }}
                  />
                </div>
                <p>Would you like to rerun this task?</p>
              </Flex>
            </StyledDashboardContentFlex>
          </Dialog>
        </StyledModal>
      )}
    </>
  );
};

export default JobDetailsOverview;