import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { Flex, PreLoader } from "../../index";
import { useSelector } from "react-redux";
import SQLError from "../Transformation/Components/SQLError";
const GPTResult = ({ GPTQueryResults }) => {
  const { postGPTQueryError, postGPTQueryLoader, postGPTQuerySuccess } =
    useSelector((state) => state.users);
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#CDE5FF",
      opacity: "0.8",
      color: "#001F25",
      textAlign: "left",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
      color: "#001F25",
      font: "normal 500 14px/20px 'Poppins', sans-serif",
      letterSpacing: "0.1px",
    },
    "td, th": {
      border: 0,
    },
  }));
  const renderTable = () => {
    if (postGPTQuerySuccess === undefined && GPTQueryResults !== null) {
      return <p>Run a script to view Results</p>;
    }
    if (Array.isArray(GPTQueryResults)) {
      if (typeof GPTQueryResults[0] === "object" && GPTQueryResults !== null) {
        return (
          <div style={{ width: "100%", overflow: "auto", maxHeight: "288px" }}>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ minWidth: 700, maxHeight: "288px" }}>
                <Table aria-label="sticky table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell sx={{ padding: "8px" }}>
                        Rows
                      </StyledTableCell>
                      {Object.keys(GPTQueryResults[0]).map((header, index) => (
                        <StyledTableCell
                          key={index}
                          align="center"
                          style={{ minWidth: 100 }}
                          sx={{ padding: "8px" }}
                        >
                          {header}
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {GPTQueryResults.map((row, rowIndex) => (
                      <StyledTableRow key={rowIndex}>
                        <StyledTableCell sx={{ padding: "0 8px" }}>
                          {rowIndex}
                        </StyledTableCell>
                        {Object.keys(row).map((header, colIndex) => (
                          <StyledTableCell
                            key={colIndex}
                            sx={{ padding: "0 8px" }}
                          >
                            {row[header] === null || row[header] === ""
                              ? "Nothing to show"
                              : row[header].value
                              ? row[header].value
                              : row[header]}
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        );
      } else {
        return (
          <div style={{ width: "100%", overflow: "auto", maxHeight: "288px" }}>
            <ul>
              {GPTQueryResults.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        );
      }
    } else if (
      typeof GPTQueryResults === "object" &&
      GPTQueryResults !== null
    ) {
      return (
        <div style={{ width: "100%", overflow: "auto", maxHeight: "288px" }}>
          <pre>{JSON.stringify(GPTQueryResults, null, 2)}</pre>
        </div>
      );
    } else if (
      typeof GPTQueryResults === "string" &&
      GPTQueryResults !== null
    ) {
      return <p>{GPTQueryResults}</p>;
    } else {
      return <p></p>;
    }
  };
  return (
    <>
      <Flex column style={{ width: "100%" }}>
        {postGPTQueryLoader ? (
          <PreLoader />
        ) : GPTQueryResults !== null && GPTQueryResults.length === 0 ? (
          <SQLError
            value={
              "⛵ BoatGPT returned an empty result, like sailing into dry seas. 🌊 📝 "
            }
            value2={
              "Try adding more details to fuel your question, ⛽ and we’ll navigate toward better insights! 🔍"
            }
          />
        ) : (
          renderTable()
        )}
      </Flex>
    </>
  );
};
export default GPTResult;
