import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Flex,
  BsChevronDown,
  BsChevronUp,
  StyledSideNavigation,
  StyledNavigationListItems,
} from "../index";

const DocumentNavigation = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const splitLocation = pathname.split('/');

  const [activeDropdown, setActiveDropdown] = useState([]);

  const checkIfActiveDropdown = (menuItemId) => {
    const newArr = [...activeDropdown];
    return (newArr || []).includes(menuItemId);
  }

  const menuList = [
    {
      id: 1,
      title: t("general.home"),
      path: "/docs",
      class: "docs",
    },
    {
      id: 2,
      title: t("general.about_databoat"),
      path: "/docs/about-databoat",
      class: "about-databoat",
    },
    {
      id: 3,
      title: t("general.overview"),
      path: "/docs/overview",
      class: "overview",
    },
    {
      id: 4,
      title: t("general.data_onboarding"),
      path: "/docs/data-onboarding",
      class: "data-onboarding",
      options: [
        // {
        //   id: 41,
        //   subMenu: t("general.connectors"),
        //   optionLink: "/docs/data-onboarding/connectors",
        //   optionClass: "connectors",
        // },
        {
          id: 42,
          subMenu: t("general.manual_upload"),
          optionLink: "/docs/data-onboarding/manual-upload",
          optionClass: "manual-upload",
        },
        {
          id: 43,
          subMenu: t("general.sql_server"),
          optionLink: "/docs/data-onboarding/sql-server",
          optionClass: "sql-server",
        },
      ]
    },
    {
      id: 5,
      title: t("general.workflow_management"),
      path: "/docs/workflow-management",
      class: "workflow-management",
      options: [
        {
          id: 51,
          subMenu: t("general.creating_a_job"),
          optionLink: "/docs/workflow-management/creating-a-job",
          optionClass: "creating-a-job",
        },
        {
          id: 52,
          subMenu: t("general.viewing_jobs"),
          optionLink: "/docs/workflow-management/viewing-jobs",
          optionClass: "viewing-jobs",
        },
      ]
    },
    {
      id: 6,
      title: t("general.data_catalog"),
      path: "/docs/data-catalog",
      class: "data-catalog",
      options: [
        {
          id: 61,
          subMenu: t("general.accessing_the_data_catalog"),
          optionLink: "/docs/data-catalog/accessing-the-data-catalog",
          optionClass: "accessing-the-data-catalog",
        },
        {
          id: 62,
          subMenu: t("general.viewing_data_assets"),
          optionLink: "/docs/data-catalog/viewing-data-assets",
          optionClass: "viewing-data-assets",
        },
      ]
    },
    {
      id: 7,
      title: t("general.transformation"),
      path: "/docs/transformation",
      class: "transformation",
    },
    {
      id: 8,
      title: t("general.faq"),
      path: "/docs/faq",
      class: "faq",
    },
  ];

  return (
    <StyledSideNavigation>
      <StyledNavigationListItems>
        {menuList.map((item) => (
          <div
            key={item.id}
            className={
              splitLocation[splitLocation.length - 1] === item.class && !item.hasOwnProperty("options") ? "nav_active" : null
            }
          >
            <div className="menu-list-container">
              <div className="title_container">
                {item.path ? (
                  <Link to={item.path} className="navLink">
                    {item.title}
                  </Link>
                  ) : (
                    <div
                      className="navLink"
                      onClick={() => {
                        if(!activeDropdown.includes(item.id)){
                          const newArr = [...activeDropdown];
                          newArr.push(item.id);
                          setActiveDropdown(newArr);
                        }
                      }}
                    >
                      {item.title}
                    </div>
                  )}
              </div>

              <div>
                {item.hasOwnProperty("options") && (
                  <div>
                    {(checkIfActiveDropdown(item.id)) ? (
                      <BsChevronUp
                        onClick={() => {
                          const newArr = [...activeDropdown];
                          setActiveDropdown(newArr.filter((current) => current !== item.id));
                        }}
                        className="icons up"
                      />
                    ) : (
                      <BsChevronDown
                        onClick={() => {
                          if(!activeDropdown.includes(item.id)){
                            const newArr = [...activeDropdown];
                            newArr.push(item.id);
                            setActiveDropdown(newArr);
                          }
                        }}
                        className="icons"
                      />
                    )}
                  </div>
                )}
              </div>
            </div>

            {item.hasOwnProperty("options") ? (
              <div className="optionsContainer" style={{ marginLeft: "1em" }}>
                {checkIfActiveDropdown(item.id)
                  ? item.options.map((options) => (
                      <div
                        className={
                          splitLocation[splitLocation.length - 1] === options.optionClass
                            ? "nav_active"
                            : null
                        }
                        key={options.id}
                      >
                        <Flex
                          column
                          gap
                          style={{ padding: "0.5em 0.75em", gap: "0.5em" }}
                        >
                          <div className={"option_flex"}>
                            <Flex>
                              <Link
                                to={options.optionLink}
                                className="option_title"
                                style={{ marginTop: 0 }}
                              >
                                {options.subMenu}
                              </Link>
                            </Flex>
                          </div>
                        </Flex>
                      </div>
                    ))
                  : null}
              </div>
            ) : null}
          </div>
        ))}
      </StyledNavigationListItems>
    </StyledSideNavigation>
  );
};
export default DocumentNavigation;
