import React from "react";
import useUpload from "./useUpload";
// import filesize from "filesize";
import {
  DragandDrop,
  StyledBackground,
  StyledDashboardContentFlex,
  Flex,
  StyledP,
  AiOutlineClose,
} from "../../index";
import CsvConverter from "./CsvConverter";

const UploadContent = ({
  files,
  errMsg,
  csvData,
  handleRemove,
  fileRejections,
  getRootProps,
  isDragActive,
  getInputProps,
  errRef,
  handlePostCSVData,
}) => {
  const allFiles =
    files.length !== 0 &&
    files.map((file) => (
      <StyledBackground key={file.path}>
        <StyledDashboardContentFlex justifyContent>
          <Flex column>
            <StyledP>{file.name}</StyledP>
            <p style={{ fontSize: "0.75em" }}>{file.size}</p>
          </Flex>
          <AiOutlineClose
            className="modal_icon"
            onClick={() => handleRemove(file.name)}
          />
        </StyledDashboardContentFlex>
      </StyledBackground>
    ));
  return (
    <>
      {files.length === 0 ? (
        <div style={{ maxWidth: "60%" }}>
          <DragandDrop
            fileRejections={fileRejections}
            getRootProps={getRootProps}
            isDragActive={isDragActive}
            getInputProps={getInputProps}
            errMsg={errMsg}
            errRef={errRef}
          />
        </div>
      ) : (
        <StyledDashboardContentFlex column>
          <Flex column>{allFiles}</Flex>
          <Flex column>
            <CsvConverter csvData={csvData} />
          </Flex>
          <StyledDashboardContentFlex justifyContent>
            <div>
              <button
                className={"next_btn save_btn"}
                onClick={handlePostCSVData}
              >
                Upload
              </button>
            </div>
          </StyledDashboardContentFlex>
        </StyledDashboardContentFlex>
      )}
    </>
  );
};

export default UploadContent;
