import React from "react";
import {
  StyledConnect,
  StyledImage,
  DynamicGrid,
  StyledModal,
  Flex,
  SelectedComponent,
  ModalHeader,
} from "../../../index";
import { iconsListItems } from "../IconListItems";
import CloseSvg from "../../../reusableComponents/svg/CloseSvg";
import { Dialog } from "@mui/material";
import { useTranslation } from "react-i18next";

const ConnectorsModal = ({
  isConnectorModalOpen,
  onClose,
  openModal,
  closeModal,
  modalOpen,
  selectedText,
  inputData,
  S3Data,
  taskId,
  handleChange,
  handleS3SourceBucketChange,
  handleS3SourceBucketBlur,
  s3fileNameError,
  s3taskPartitionColumn,
  handleS3TaskPartitionColumn,
  dbDataValues,
  handleSQLServerValuesChange,
  sqlError,
  setQueryDBName,
  handlequeryDataBaseName,
  sqlIngestions,
  selectedOption,
  handleSelectChange,
  optionsArray,
  sqlServerdataQueries,
  handleQueriesTableNameChange,
  queries,
  handleQueriesChange,
  checkboxValues,
  handleCheckboxChange,
  partitonColValue,
  handleQueryPartitionChange,
  addMoreSQLServerQueries,
  tableHead,
  tableData,
  handleDatabaseNameChange,
  databaseNameLoader,
  databaseName,
  handleSchemaNameChange,
  schemas,
  handleTableNameChange,
  tables,
  handleTableIngestModeOption,
  tableOptions,
  handleDeltaColumnChange,
  deltaColumn,
  handlePartionChange,
  handlePartitioningChange,
  partitionColumn,
  addRow,
  //
  mySQLDataValues,
  handlemySQLServerValuesChange,
  mySQLError,
  handlemySQLqueryDataBaseName,
  mysqlIngestions,
  mySQLselectedOption,
  handlemySQLSelectChange,
  mySQLoptionsArray,
  mySQLServerdataQueries,
  handlemySQLQueriesTableNameChange,
  mySQLqueries,
  handlemySQLQueriesChange,
  mySQLcheckboxValues,
  handlemySQLCheckboxChange,
  mySQLpartitonColValue,
  handlemySQLQueryPartitionChange,
  addMoremySQLServerQueries,
  mySQLtableHead,
  mySQLtableData,
  handlemySQLDatabaseNameChange,
  mySQLdatabaseNameLoader,
  mySQLdatabaseName,
  handlemySQLSchemaNameChange,
  mySQLschemas,
  handlemySQLTableNameChange,
  mySQLtables,
  handlemySQLTableIngestModeOption,
  mySQLtableOptions,
  handlemySQLDeltaColumnChange,
  mySQLdeltaColumn,
  handlemySQLPartionChange,
  handlemySQLPartitioningChange,
  mySQLpartitionColumn,
  mySQLaddRow,
  //
  postgresDataValues,
  handlepostgresServerValuesChange,
  postgresError,
  handlepostgresqueryDataBaseName,
  PostgresSQLIngestions,
  postgresselectedOption,
  handlepostgresSelectChange,
  postgresoptionsArray,
  postgresServerdataQueries,
  handlepostgresQueriesTableNameChange,
  postgresqueries,
  handlepostgresQueriesChange,
  postgrescheckboxValues,
  handlepostgresCheckboxChange,
  postgrespartitonColValue,
  handlepostgresQueryPartitionChange,
  addMorepostgresServerQueries,
  postgrestableHead,
  postgrestableData,
  handlepostgresDatabaseNameChange,
  postgresdatabaseNameLoader,
  postgresdatabaseName,
  handlepostgresSchemaNameChange,
  postgresschemas,
  handlepostgresTableNameChange,
  postgrestables,
  handlepostgresTableIngestModeOption,
  postgrestableOptions,
  handlepostgresDeltaColumnChange,
  postgresdeltaColumn,
  handlepostgresPartionChange,
  handlepostgresPartitioningChange,
  postgrespartitionColumn,
  postgresaddRow,
  // -----------------
  oracleDataValues,
  handleoracleServerValuesChange,
  oracleError,
  OracleIngestions,
  oracleselectedOption,
  handleoracleSelectChange,
  oracleoptionsArray,
  oracleServerdataQueries,
  handleoracleQueriesTableNameChange,
  oraclequeries,
  handleoracleQueriesChange,
  oraclecheckboxValues,
  handleoracleCheckboxChange,
  oraclepartitonColValue,
  handleoracleQueryPartitionChange,
  addMoreoracleServerQueries,
  oracletableHead,
  oracletableData,
  handleoracleSchemaNameChange,
  oracleschemas,
  handleoracleTableNameChange,
  oracletables,
  handleoracleTableIngestModeOption,
  oracletableOptions,
  handleoracleDeltaColumnChange,
  oracledeltaColumn,
  handleoraclePartionChange,
  handleoraclePartitioningChange,
  oraclepartitionColumn,
  oracleaddRow,
  // ----
  ApiData,
  handleAPIChange,
  apiHttp,
  httpData,
  httpvalue,
  handleHttpChange,
  renderKeyValue,
  apiparamKey,
  apihandleKeyChange,
  apiparamValue,
  apihandleValueChange,
  GCSData,
  handleGCSChange,
  handleGCSSourceBucketChange,
  handleGCSSourceBucketBlur,
  gcsfileNameError,
  gcstaskPartitionColumn,
  handleGCSTaskPartitionColumn,
  //---
  bigQueryData,
  handleBigQueryValueChange,
  credential,
  credentialError,
  handleCredentialJsonChange,
  bqTables,
  bigQueryTableHead,
  addBqTablesRow,
  handleProjectIdChange,
  datasets,
  handleDataSetIdChange,
  allTables,
  handleTableIdChange,
  isTableId,
  handleBigQueryLoadTypeChange,
  allDeltaColumn,
  handleBigQueryDeltaColumnChange,
  handleBigQueryPartionChange,
  allPartitionColumn,
  handleBigQueryPartitioningChange,
  handleOutputTableName,
  //---excat
  excatData,
  handleExcatChange,
  isRequiredFieldFilled,
  excatTableHead,
  myExcatloadType,
  excatTableData,
  excatTableOptions,
  handleSwitchChange,
  handleSyncModeChange,
  handlePrimaryKeyChange,
}) => {
  const { t } = useTranslation();

  if (!isConnectorModalOpen) return null;

  return (
    <>
      <StyledModal>
        <Dialog
          height="fit-content"
          open={isConnectorModalOpen}
          fullWidth
          PaperProps={{ sx: { borderRadius: "28px" } }}
        >
          <div>
            <ModalHeader>
              <p>{t("jobs.form.inputs.task.modal.title")}</p>
              <div onClick={onClose}>
                <CloseSvg style={{ float: "right" }} className="modal_icon" />
              </div>
            </ModalHeader>
            <StyledConnect>
              <DynamicGrid>
                {iconsListItems.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="icon_container"
                      onClick={() => openModal(data.component, data.text)}
                    >
                      <Flex>
                        <StyledImage
                          src={`../../../../images/${data.image}`}
                          alt={data.text}
                        />
                      </Flex>
                      <p className="text">{data.text}</p>
                    </div>
                  );
                })}
              </DynamicGrid>
            </StyledConnect>
          </div>
        </Dialog>
      </StyledModal>
      {modalOpen && (
        <StyledModal>
          <Dialog
            height={"fit-content"}
            open={modalOpen}
            fullWidth={true}
            maxWidth="md"
            PaperProps={{ sx: { borderRadius: "28px" } }}
          >
            <div>
              <ModalHeader>
                <p>
                  {selectedText}{" "}
                  {t("jobs.form.inputs.task.modal.connectors.server.title")}
                </p>
                <div onClick={closeModal}>
                  <CloseSvg style={{ float: "right" }} className="modal_icon" />
                </div>
              </ModalHeader>
              <SelectedComponent
                selectedText={selectedText}
                inputData={inputData}
                S3Data={S3Data}
                handleChange={handleChange}
                handleS3SourceBucketChange={handleS3SourceBucketChange}
                handleS3SourceBucketBlur={handleS3SourceBucketBlur}
                s3fileNameError={s3fileNameError}
                s3taskPartitionColumn={s3taskPartitionColumn}
                handleS3TaskPartitionColumn={handleS3TaskPartitionColumn}
                onClose={onClose}
                dbDataValues={dbDataValues}
                handleSQLServerValuesChange={handleSQLServerValuesChange}
                sqlError={sqlError}
                setQueryDBName={setQueryDBName}
                handlequeryDataBaseName={handlequeryDataBaseName}
                sqlIngestions={sqlIngestions}
                selectedOption={selectedOption}
                handleSelectChange={handleSelectChange}
                optionsArray={optionsArray}
                sqlServerdataQueries={sqlServerdataQueries}
                handleQueriesTableNameChange={handleQueriesTableNameChange}
                queries={queries}
                taskId={taskId}
                handleQueriesChange={handleQueriesChange}
                checkboxValues={checkboxValues}
                handleCheckboxChange={handleCheckboxChange}
                partitonColValue={partitonColValue}
                handleQueryPartitionChange={handleQueryPartitionChange}
                addMoreSQLServerQueries={addMoreSQLServerQueries}
                tableHead={tableHead}
                tableData={tableData}
                handleDatabaseNameChange={handleDatabaseNameChange}
                databaseNameLoader={databaseNameLoader}
                databaseName={databaseName}
                handleSchemaNameChange={handleSchemaNameChange}
                schemas={schemas}
                handleTableNameChange={handleTableNameChange}
                tables={tables}
                handleTableIngestModeOption={handleTableIngestModeOption}
                tableOptions={tableOptions}
                handleDeltaColumnChange={handleDeltaColumnChange}
                deltaColumn={deltaColumn}
                handlePartionChange={handlePartionChange}
                handlePartitioningChange={handlePartitioningChange}
                partitionColumn={partitionColumn}
                addRow={addRow}
                mySQLDataValues={mySQLDataValues}
                handlemySQLServerValuesChange={handlemySQLServerValuesChange}
                mySQLError={mySQLError}
                handlemySQLqueryDataBaseName={handlemySQLqueryDataBaseName}
                mysqlIngestions={mysqlIngestions}
                mySQLselectedOption={mySQLselectedOption}
                handlemySQLSelectChange={handlemySQLSelectChange}
                mySQLoptionsArray={mySQLoptionsArray}
                mySQLServerdataQueries={mySQLServerdataQueries}
                handlemySQLQueriesTableNameChange={
                  handlemySQLQueriesTableNameChange
                }
                mySQLqueries={mySQLqueries}
                handlemySQLQueriesChange={handlemySQLQueriesChange}
                mySQLcheckboxValues={mySQLcheckboxValues}
                handlemySQLCheckboxChange={handlemySQLCheckboxChange}
                mySQLpartitonColValue={mySQLpartitonColValue}
                handlemySQLQueryPartitionChange={
                  handlemySQLQueryPartitionChange
                }
                addMoremySQLServerQueries={addMoremySQLServerQueries}
                mySQLtableHead={mySQLtableHead}
                mySQLtableData={mySQLtableData}
                handlemySQLDatabaseNameChange={handlemySQLDatabaseNameChange}
                mySQLdatabaseNameLoader={mySQLdatabaseNameLoader}
                mySQLdatabaseName={mySQLdatabaseName}
                handlemySQLSchemaNameChange={handlemySQLSchemaNameChange}
                mySQLschemas={mySQLschemas}
                handlemySQLTableNameChange={handlemySQLTableNameChange}
                mySQLtables={mySQLtables}
                handlemySQLTableIngestModeOption={
                  handlemySQLTableIngestModeOption
                }
                mySQLtableOptions={mySQLtableOptions}
                handlemySQLDeltaColumnChange={handlemySQLDeltaColumnChange}
                mySQLdeltaColumn={mySQLdeltaColumn}
                handlemySQLPartionChange={handlemySQLPartionChange}
                handlemySQLPartitioningChange={handlemySQLPartitioningChange}
                mySQLpartitionColumn={mySQLpartitionColumn}
                mySQLaddRow={mySQLaddRow}
                //------
                postgresDataValues={postgresDataValues}
                handlepostgresServerValuesChange={
                  handlepostgresServerValuesChange
                }
                postgresError={postgresError}
                handlepostgresqueryDataBaseName={
                  handlepostgresqueryDataBaseName
                }
                PostgresSQLIngestions={PostgresSQLIngestions}
                postgresselectedOption={postgresselectedOption}
                handlepostgresSelectChange={handlepostgresSelectChange}
                postgresoptionsArray={postgresoptionsArray}
                postgresServerdataQueries={postgresServerdataQueries}
                handlepostgresQueriesTableNameChange={
                  handlepostgresQueriesTableNameChange
                }
                postgresqueries={postgresqueries}
                handlepostgresQueriesChange={handlepostgresQueriesChange}
                postgrescheckboxValues={postgrescheckboxValues}
                handlepostgresCheckboxChange={handlepostgresCheckboxChange}
                postgrespartitonColValue={postgrespartitonColValue}
                handlepostgresQueryPartitionChange={
                  handlepostgresQueryPartitionChange
                }
                addMorepostgresServerQueries={addMorepostgresServerQueries}
                postgrestableHead={postgrestableHead}
                postgrestableData={postgrestableData}
                handlepostgresDatabaseNameChange={
                  handlepostgresDatabaseNameChange
                }
                postgresdatabaseNameLoader={postgresdatabaseNameLoader}
                postgresdatabaseName={postgresdatabaseName}
                handlepostgresSchemaNameChange={handlepostgresSchemaNameChange}
                postgresschemas={postgresschemas}
                handlepostgresTableNameChange={handlepostgresTableNameChange}
                postgrestables={postgrestables}
                handlepostgresTableIngestModeOption={
                  handlepostgresTableIngestModeOption
                }
                postgrestableOptions={postgrestableOptions}
                handlepostgresDeltaColumnChange={
                  handlepostgresDeltaColumnChange
                }
                postgresdeltaColumn={postgresdeltaColumn}
                handlepostgresPartionChange={handlepostgresPartionChange}
                handlepostgresPartitioningChange={
                  handlepostgresPartitioningChange
                }
                postgrespartitionColumn={postgrespartitionColumn}
                postgresaddRow={postgresaddRow}
                // oracle
                oracleDataValues={oracleDataValues}
                handleoracleServerValuesChange={handleoracleServerValuesChange}
                oracleError={oracleError}
                OracleIngestions={OracleIngestions}
                oracleselectedOption={oracleselectedOption}
                handleoracleSelectChange={handleoracleSelectChange}
                oracleoptionsArray={oracleoptionsArray}
                oracleServerdataQueries={oracleServerdataQueries}
                handleoracleQueriesTableNameChange={
                  handleoracleQueriesTableNameChange
                }
                oraclequeries={oraclequeries}
                handleoracleQueriesChange={handleoracleQueriesChange}
                oraclecheckboxValues={oraclecheckboxValues}
                handleoracleCheckboxChange={handleoracleCheckboxChange}
                oraclepartitonColValue={oraclepartitonColValue}
                handleoracleQueryPartitionChange={
                  handleoracleQueryPartitionChange
                }
                addMoreoracleServerQueries={addMoreoracleServerQueries}
                oracletableHead={oracletableHead}
                oracletableData={oracletableData}
                handleoracleSchemaNameChange={handleoracleSchemaNameChange}
                oracleschemas={oracleschemas}
                handleoracleTableNameChange={handleoracleTableNameChange}
                oracletables={oracletables}
                handleoracleTableIngestModeOption={
                  handleoracleTableIngestModeOption
                }
                oracletableOptions={oracletableOptions}
                handleoracleDeltaColumnChange={handleoracleDeltaColumnChange}
                oracledeltaColumn={oracledeltaColumn}
                handleoraclePartionChange={handleoraclePartionChange}
                handleoraclePartitioningChange={handleoraclePartitioningChange}
                oraclepartitionColumn={oraclepartitionColumn}
                oracleaddRow={oracleaddRow}
                // ----
                ApiData={ApiData}
                handleAPIChange={handleAPIChange}
                apiHttp={apiHttp}
                httpData={httpData}
                httpvalue={httpvalue}
                handleHttpChange={handleHttpChange}
                renderKeyValue={renderKeyValue}
                apiparamKey={apiparamKey}
                apihandleKeyChange={apihandleKeyChange}
                apiparamValue={apiparamValue}
                apihandleValueChange={apihandleValueChange}
                GCSData={GCSData}
                handleGCSChange={handleGCSChange}
                handleGCSSourceBucketChange={handleGCSSourceBucketChange}
                handleGCSSourceBucketBlur={handleGCSSourceBucketBlur}
                gcsfileNameError={gcsfileNameError}
                gcstaskPartitionColumn={gcstaskPartitionColumn}
                handleGCSTaskPartitionColumn={handleGCSTaskPartitionColumn}
                bigQueryData={bigQueryData}
                handleBigQueryValueChange={handleBigQueryValueChange}
                credential={credential}
                credentialError={credentialError}
                handleCredentialJsonChange={handleCredentialJsonChange}
                bqTables={bqTables}
                bigQueryTableHead={bigQueryTableHead}
                addBqTablesRow={addBqTablesRow}
                handleProjectIdChange={handleProjectIdChange}
                datasets={datasets}
                handleDataSetIdChange={handleDataSetIdChange}
                allTables={allTables}
                handleTableIdChange={handleTableIdChange}
                isTableId={isTableId}
                handleBigQueryLoadTypeChange={handleBigQueryLoadTypeChange}
                allDeltaColumn={allDeltaColumn}
                handleBigQueryDeltaColumnChange={
                  handleBigQueryDeltaColumnChange
                }
                handleBigQueryPartionChange={handleBigQueryPartionChange}
                allPartitionColumn={allPartitionColumn}
                handleBigQueryPartitioningChange={
                  handleBigQueryPartitioningChange
                }
                handleOutputTableName={handleOutputTableName}
                excatData={excatData}
                handleExcatChange={handleExcatChange}
                isRequiredFieldFilled={isRequiredFieldFilled}
                excatTableHead={excatTableHead}
                myExcatloadType={myExcatloadType}
                excatTableData={excatTableData}
                excatTableOptions={excatTableOptions}
                handleSwitchChange={handleSwitchChange}
                handleSyncModeChange={handleSyncModeChange}
                handlePrimaryKeyChange={handlePrimaryKeyChange}
              />
            </div>
          </Dialog>
        </StyledModal>
      )}
    </>
  );
};

export default ConnectorsModal;
