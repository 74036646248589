import React, { useState } from "react";
import useEditJobHook from "../customHook/useEditJobHook";
import {
  StyledJobContainer,
  StyledPortalContent,
  StyledDashboardContentFlex,
  FieldSet,
  Tabs,
  Frequency,
  JobsSvg,
  WorkFlow,
  DashBoardSvg,
} from "../../../index";
import EditTask from "./EditTask";
import { useTranslation } from "react-i18next";

const EditFormComponent = () => {
  const {
    jobNameRef,
    date,
    handleDateChange,
    time,
    handleTimeChange,
    frequencyOption,
    handleFrequencyOption,
    frequencydata,
    startHourMinutes,
    handleStartHourMinutesChange,
    dailyTime,
    handleDailyTimeChange,
    selectedDays,
    handleDayToggle,
    startWeeklyTime,
    handleWeeklyStartTimeChange,
    //task
    taskData,
    handleTaskNameChange,
    handleParametersTypeChange,
    handleKeyChange,
    handleValueChange,
    formattedJson,
    handleJsonDataChange,
    handleTaskTypeChange,
    handleCodePathChange,
    handleSourceFieldChange,

    //CONNECTORS
    isConnectorModalOpen,
    onClose,
    openModal,
    closeModal,
    modalOpen,
    selectedComponent,
    selectedText,
    inputData,
    //s3
    S3Data,
    handleChange,
    handleS3SourceBucketChange,
    handleS3SourceBucketBlur,
    s3fileNameError,
    handleS3TaskPartitionColumn,
    //----------SQL SERVER ----------------------------------------------------
    dbDataValues,
    handleSQLServerValuesChange,
    sqlError,
    setQueryDBName,
    handlequeryDataBaseName,
    sqlIngestions,
    selectedOption,
    handleSelectChange,
    optionsArray,
    sqlServerdataQueries,
    handleQueriesTableNameChange,
    queries,
    handleQueriesChange,
    checkboxValues,
    handleCheckboxChange,
    partitonColValue,
    handleQueryPartitionChange,
    addMoreSQLServerQueries,
    tableHead,
    tableData,
    handleDatabaseNameChange,
    databaseNameLoader,
    databaseName,
    handleSchemaNameChange,
    schemas,
    handleTableNameChange,
    tables,
    handleTableIngestModeOption,
    tableOptions,
    handleDeltaColumnChange,
    deltaColumn,
    handlePartionChange,
    handlePartitioningChange,
    partitionColumn,
    addRow,
    //-----------------MYSQL
    mySQLDataValues,
    handlemySQLServerValuesChange,
    mySQLError,
    handlemySQLqueryDataBaseName,
    mysqlIngestions,
    mySQLselectedOption,
    handlemySQLSelectChange,
    mySQLoptionsArray,
    mySQLServerdataQueries,
    handlemySQLQueriesTableNameChange,
    mySQLqueries,
    handlemySQLQueriesChange,
    mySQLcheckboxValues,
    handlemySQLCheckboxChange,
    mySQLpartitonColValue,
    handlemySQLQueryPartitionChange,
    addMoremySQLServerQueries,
    mySQLtableHead,
    mySQLtableData,
    handlemySQLDatabaseNameChange,
    mySQLdatabaseNameLoader,
    mySQLdatabaseName,
    handlemySQLSchemaNameChange,
    mySQLschemas,
    handlemySQLTableNameChange,
    mySQLtables,
    handlemySQLTableIngestModeOption,
    mySQLtableOptions,
    handlemySQLDeltaColumnChange,
    mySQLdeltaColumn,
    handlemySQLPartionChange,
    handlemySQLPartitioningChange,
    mySQLpartitionColumn,
    mySQLaddRow,
    //--------postgress
    //------------------POSTGRESS
    postgresDataValues,
    handlepostgresServerValuesChange,
    postgresError,
    handlepostgresqueryDataBaseName,
    PostgresSQLIngestions,
    postgresselectedOption,
    handlepostgresSelectChange,
    postgresoptionsArray,
    postgresServerdataQueries,
    handlepostgresQueriesTableNameChange,
    postgresqueries,
    handlepostgresQueriesChange,
    postgrescheckboxValues,
    handlepostgresCheckboxChange,
    postgrespartitonColValue,
    handlepostgresQueryPartitionChange,
    addMorepostgresServerQueries,
    postgrestableHead,
    postgrestableData,
    handlepostgresDatabaseNameChange,
    postgresdatabaseNameLoader,
    postgresdatabaseName,
    handlepostgresSchemaNameChange,
    postgresschemas,
    handlepostgresTableNameChange,
    postgrestables,
    handlepostgresTableIngestModeOption,
    postgrestableOptions,
    handlepostgresDeltaColumnChange,
    postgresdeltaColumn,
    handlepostgresPartionChange,
    handlepostgresPartitioningChange,
    postgrespartitionColumn,
    postgresaddRow,
    //oracle
    oracleDataValues,
    handleoracleServerValuesChange,
    oracleError,
    OracleIngestions,
    oracleselectedOption,
    handleoracleSelectChange,
    oracleoptionsArray,
    oracleServerdataQueries,
    handleoracleQueriesTableNameChange,
    oraclequeries,
    handleoracleQueriesChange,
    oraclecheckboxValues,
    handleoracleCheckboxChange,
    oraclepartitonColValue,
    handleoracleQueryPartitionChange,
    addMoreoracleServerQueries,
    oracletableHead,
    oracletableData,
    handleoracleSchemaNameChange,
    oracleschemas,
    handleoracleTableNameChange,
    oracletables,
    handleoracleTableIngestModeOption,
    oracletableOptions,
    handleoracleDeltaColumnChange,
    oracledeltaColumn,
    handleoraclePartionChange,
    handleoraclePartitioningChange,
    oraclepartitionColumn,
    oracleaddRow,
    //API
    ApiData,
    handleAPIChange,
    apiHttp,
    httpData,
    httpvalue,
    handleHttpChange,
    renderKeyValue,
    apihandleKeyChange,
    apihandleValueChange,
    //-------GCS
    GCSData,
    handleGCSChange,
    handleGCSSourceBucketChange,
    gcsfileNameError,
    handleGCSSourceBucketBlur,
    handleGCSTaskPartitionColumn,
    //bigquery
    bigQueryData,
    handleBigQueryValueChange,
    credential,
    credentialError,
    handleCredentialJsonChange,
    bqTables,
    bigQueryTableHead,
    addBqTablesRow,
    handleProjectIdChange,
    datasets,
    handleDataSetIdChange,
    allTables,
    handleTableIdChange,
    isTableId,
    handleBigQueryLoadTypeChange,
    allDeltaColumn,
    handleBigQueryDeltaColumnChange,
    handleBigQueryPartionChange,
    allPartitionColumn,
    handleBigQueryPartitioningChange,
    handleOutputTableName,
    //---excat
    excatData,
    handleExcatChange,
    isRequiredFieldFilled,
    excatTableHead,
    myExcatloadType,
    excatTableData,
    excatTableOptions,
    handleSwitchChange,
    handleSyncModeChange,
    handlePrimaryKeyChange,
    //----------------ENDS ---------
    handleScriptNameChange,
    // handleEngineType,
    handleAddMoreTask,
    handleRemoveFromUI,
    tags,
    handleTagsChange,
    alertValue,
    handleAlertValueChange,
    emailto,
    handleEmailToChange,
    emailcc,
    handleEmailccChange,
    emailsubject,
    handleEmailSubjectChange,
    emailmessage,
    handleEmailMessageChange,
    slackURL,
    handleSlackURLChange,
    teamsURL,
    handleTeamsURLChange,
    handlePatchJob,
  } = useEditJobHook();

  const { t } = useTranslation();

  const fill = "#006399";
  const size = "18";
  const tabData = [
    {
      to: "/yedaflow",
      icon: <WorkFlow fill={fill} width={size} height={size} />,
      label: t("workflow.header_flow.management"),
    },
    {
      to: "/dashboard",
      icon: <DashBoardSvg fill={fill} width={size} height={size} />,
      label: t("workflow.header_flow.dashboard"),
    },
    {
      to: "/create-job",
      icon: <JobsSvg fill={fill} width={size} height={size} />,
      label: t("workflow.header_flow.jobs"),
    },
  ];
  const [isJobNameFocused, setIsJobNameFocuse] = useState(false);
  const [isStartTimeFocused, setIsStartTimeFocuse] = useState(false);

  const [isStartDateFocused, setIsStartDateFocuse] = useState(false);

  return (
    <StyledPortalContent>
      <StyledJobContainer>
        <StyledDashboardContentFlex column>
          <Tabs tabs={tabData} />
          <div style={{ height: "90vh" }}>
            <StyledDashboardContentFlex column>
              <FieldSet
                legendTitle={t("jobs.form.inputs.job_name")}
                type={"text"}
                name={"JobName"}
                id={"JobName"}
                value={jobNameRef.current}
                readOnly={true}
                isInputFocused={isJobNameFocused}
                placeholder={t("jobs.form.inputs.job_name")}
                onFocus={() => setIsJobNameFocuse(true)}
                onBlur={() => setIsJobNameFocuse(false)}
              />
              <StyledDashboardContentFlex>
                <div style={{ width: "100%" }}>
                  <FieldSet
                    legendTitle={t("jobs.form.inputs.start_date")}
                    type={"date"}
                    name={"StartDate*"}
                    id={"StartDate*"}
                    value={date}
                    onChange={handleDateChange}
                    isInputFocused={isStartDateFocused}
                    onFocus={() => setIsStartDateFocuse(true)}
                    onBlur={() => setIsStartDateFocuse(false)}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <FieldSet
                    legendTitle={t("jobs.form.inputs.start_time")}
                    type={"time"}
                    name={"StartTime*"}
                    id={"StartTime*"}
                    value={time}
                    onChange={handleTimeChange}
                    isInputFocused={isStartTimeFocused}
                    onFocus={() => setIsStartTimeFocuse(true)}
                    onBlur={() => setIsStartTimeFocuse(false)}
                  />
                </div>
              </StyledDashboardContentFlex>
              <Frequency
                frequencyOption={frequencyOption}
                handleFrequencyOption={handleFrequencyOption}
                frequencydata={frequencydata}
                startHourMinutes={startHourMinutes}
                handleStartHourMinutesChange={handleStartHourMinutesChange}
                dailyTime={dailyTime}
                handleDailyTimeChange={handleDailyTimeChange}
                selectedDays={selectedDays}
                handleDayToggle={handleDayToggle}
                startWeeklyTime={startWeeklyTime}
                handleWeeklyStartTimeChange={handleWeeklyStartTimeChange}
              />
              <EditTask
                taskData={taskData}
                handleTaskNameChange={handleTaskNameChange}
                handleParametersTypeChange={handleParametersTypeChange}
                handleKeyChange={handleKeyChange}
                handleValueChange={handleValueChange}
                formattedJson={formattedJson}
                handleJsonDataChange={handleJsonDataChange}
                handleTaskTypeChange={handleTaskTypeChange}
                isConnectorModalOpen={isConnectorModalOpen}
                handleCodePathChange={handleCodePathChange}
                handleSourceFieldChange={handleSourceFieldChange}
                onClose={onClose}
                openModal={openModal}
                closeModal={closeModal}
                modalOpen={modalOpen}
                selectedComponent={selectedComponent}
                selectedText={selectedText}
                //----------------------------
                inputData={inputData}
                S3Data={S3Data}
                handleChange={handleChange}
                handleS3SourceBucketChange={handleS3SourceBucketChange}
                handleS3SourceBucketBlur={handleS3SourceBucketBlur}
                s3fileNameError={s3fileNameError}
                handleS3TaskPartitionColumn={handleS3TaskPartitionColumn}
                dbDataValues={dbDataValues}
                handleSQLServerValuesChange={handleSQLServerValuesChange}
                sqlError={sqlError}
                setQueryDBName={setQueryDBName}
                handlequeryDataBaseName={handlequeryDataBaseName}
                sqlIngestions={sqlIngestions}
                selectedOption={selectedOption}
                handleSelectChange={handleSelectChange}
                optionsArray={optionsArray}
                sqlServerdataQueries={sqlServerdataQueries}
                handleQueriesTableNameChange={handleQueriesTableNameChange}
                queries={queries}
                handleQueriesChange={handleQueriesChange}
                checkboxValues={checkboxValues}
                handleCheckboxChange={handleCheckboxChange}
                partitonColValue={partitonColValue}
                handleQueryPartitionChange={handleQueryPartitionChange}
                addMoreSQLServerQueries={addMoreSQLServerQueries}
                tableHead={tableHead}
                tableData={tableData}
                handleDatabaseNameChange={handleDatabaseNameChange}
                databaseNameLoader={databaseNameLoader}
                databaseName={databaseName}
                handleSchemaNameChange={handleSchemaNameChange}
                schemas={schemas}
                handleTableNameChange={handleTableNameChange}
                tables={tables}
                handleTableIngestModeOption={handleTableIngestModeOption}
                tableOptions={tableOptions}
                handleDeltaColumnChange={handleDeltaColumnChange}
                deltaColumn={deltaColumn}
                handlePartionChange={handlePartionChange}
                handlePartitioningChange={handlePartitioningChange}
                partitionColumn={partitionColumn}
                addRow={addRow}
                //--------------------------------------MY SQL
                mySQLDataValues={mySQLDataValues}
                handlemySQLServerValuesChange={handlemySQLServerValuesChange}
                mySQLError={mySQLError}
                handlemySQLqueryDataBaseName={handlemySQLqueryDataBaseName}
                mysqlIngestions={mysqlIngestions}
                mySQLselectedOption={mySQLselectedOption}
                handlemySQLSelectChange={handlemySQLSelectChange}
                mySQLoptionsArray={mySQLoptionsArray}
                mySQLServerdataQueries={mySQLServerdataQueries}
                handlemySQLQueriesTableNameChange={
                  handlemySQLQueriesTableNameChange
                }
                mySQLqueries={mySQLqueries}
                handlemySQLQueriesChange={handlemySQLQueriesChange}
                mySQLcheckboxValues={mySQLcheckboxValues}
                handlemySQLCheckboxChange={handlemySQLCheckboxChange}
                mySQLpartitonColValue={mySQLpartitonColValue}
                handlemySQLQueryPartitionChange={
                  handlemySQLQueryPartitionChange
                }
                addMoremySQLServerQueries={addMoremySQLServerQueries}
                mySQLtableHead={mySQLtableHead}
                mySQLtableData={mySQLtableData}
                handlemySQLDatabaseNameChange={handlemySQLDatabaseNameChange}
                mySQLdatabaseNameLoader={mySQLdatabaseNameLoader}
                mySQLdatabaseName={mySQLdatabaseName}
                handlemySQLSchemaNameChange={handlemySQLSchemaNameChange}
                mySQLschemas={mySQLschemas}
                handlemySQLTableNameChange={handlemySQLTableNameChange}
                mySQLtables={mySQLtables}
                handlemySQLTableIngestModeOption={
                  handlemySQLTableIngestModeOption
                }
                mySQLtableOptions={mySQLtableOptions}
                handlemySQLDeltaColumnChange={handlemySQLDeltaColumnChange}
                mySQLdeltaColumn={mySQLdeltaColumn}
                handlemySQLPartionChange={handlemySQLPartionChange}
                handlemySQLPartitioningChange={handlemySQLPartitioningChange}
                mySQLpartitionColumn={mySQLpartitionColumn}
                mySQLaddRow={mySQLaddRow}
                //----------------------------------------
                postgresDataValues={postgresDataValues}
                handlepostgresServerValuesChange={
                  handlepostgresServerValuesChange
                }
                postgresError={postgresError}
                handlepostgresqueryDataBaseName={
                  handlepostgresqueryDataBaseName
                }
                PostgresSQLIngestions={PostgresSQLIngestions}
                postgresselectedOption={postgresselectedOption}
                handlepostgresSelectChange={handlepostgresSelectChange}
                postgresoptionsArray={postgresoptionsArray}
                postgresServerdataQueries={postgresServerdataQueries}
                handlepostgresQueriesTableNameChange={
                  handlepostgresQueriesTableNameChange
                }
                postgresqueries={postgresqueries}
                handlepostgresQueriesChange={handlepostgresQueriesChange}
                postgrescheckboxValues={postgrescheckboxValues}
                handlepostgresCheckboxChange={handlepostgresCheckboxChange}
                postgrespartitonColValue={postgrespartitonColValue}
                handlepostgresQueryPartitionChange={
                  handlepostgresQueryPartitionChange
                }
                addMorepostgresServerQueries={addMorepostgresServerQueries}
                postgrestableHead={postgrestableHead}
                postgrestableData={postgrestableData}
                handlepostgresDatabaseNameChange={
                  handlepostgresDatabaseNameChange
                }
                postgresdatabaseNameLoader={postgresdatabaseNameLoader}
                postgresdatabaseName={postgresdatabaseName}
                handlepostgresSchemaNameChange={handlepostgresSchemaNameChange}
                postgresschemas={postgresschemas}
                handlepostgresTableNameChange={handlepostgresTableNameChange}
                postgrestables={postgrestables}
                handlepostgresTableIngestModeOption={
                  handlepostgresTableIngestModeOption
                }
                postgrestableOptions={postgrestableOptions}
                handlepostgresDeltaColumnChange={
                  handlepostgresDeltaColumnChange
                }
                postgresdeltaColumn={postgresdeltaColumn}
                handlepostgresPartionChange={handlepostgresPartionChange}
                handlepostgresPartitioningChange={
                  handlepostgresPartitioningChange
                }
                postgrespartitionColumn={postgrespartitionColumn}
                postgresaddRow={postgresaddRow}
                //--------------------------------------
                oracleDataValues={oracleDataValues}
                handleoracleServerValuesChange={handleoracleServerValuesChange}
                oracleError={oracleError}
                OracleIngestions={OracleIngestions}
                oracleselectedOption={oracleselectedOption}
                handleoracleSelectChange={handleoracleSelectChange}
                oracleoptionsArray={oracleoptionsArray}
                oracleServerdataQueries={oracleServerdataQueries}
                handleoracleQueriesTableNameChange={
                  handleoracleQueriesTableNameChange
                }
                oraclequeries={oraclequeries}
                handleoracleQueriesChange={handleoracleQueriesChange}
                oraclecheckboxValues={oraclecheckboxValues}
                handleoracleCheckboxChange={handleoracleCheckboxChange}
                oraclepartitonColValue={oraclepartitonColValue}
                handleoracleQueryPartitionChange={
                  handleoracleQueryPartitionChange
                }
                addMoreoracleServerQueries={addMoreoracleServerQueries}
                oracletableHead={oracletableHead}
                oracletableData={oracletableData}
                handleoracleSchemaNameChange={handleoracleSchemaNameChange}
                oracleschemas={oracleschemas}
                handleoracleTableNameChange={handleoracleTableNameChange}
                oracletables={oracletables}
                handleoracleTableIngestModeOption={
                  handleoracleTableIngestModeOption
                }
                oracletableOptions={oracletableOptions}
                handleoracleDeltaColumnChange={handleoracleDeltaColumnChange}
                oracledeltaColumn={oracledeltaColumn}
                handleoraclePartionChange={handleoraclePartionChange}
                handleoraclePartitioningChange={handleoraclePartitioningChange}
                oraclepartitionColumn={oraclepartitionColumn}
                oracleaddRow={oracleaddRow}
                //---------------------------------------
                ApiData={ApiData}
                handleAPIChange={handleAPIChange}
                apiHttp={apiHttp}
                httpData={httpData}
                httpvalue={httpvalue}
                handleHttpChange={handleHttpChange}
                renderKeyValue={renderKeyValue}
                apihandleKeyChange={apihandleKeyChange}
                apihandleValueChange={apihandleValueChange}
                //----------------------------------------
                GCSData={GCSData}
                handleGCSChange={handleGCSChange}
                handleGCSSourceBucketChange={handleGCSSourceBucketChange}
                handleGCSSourceBucketBlur={handleGCSSourceBucketBlur}
                gcsfileNameError={gcsfileNameError}
                handleGCSTaskPartitionColumn={handleGCSTaskPartitionColumn}
                //-------------------------------------------
                bigQueryData={bigQueryData}
                bqTables={bqTables}
                handleBigQueryValueChange={handleBigQueryValueChange}
                credential={credential}
                credentialError={credentialError}
                handleCredentialJsonChange={handleCredentialJsonChange}
                bigQueryTableHead={bigQueryTableHead}
                addBqTablesRow={addBqTablesRow}
                handleProjectIdChange={handleProjectIdChange}
                datasets={datasets}
                handleDataSetIdChange={handleDataSetIdChange}
                allTables={allTables}
                handleTableIdChange={handleTableIdChange}
                isTableId={isTableId}
                handleBigQueryLoadTypeChange={handleBigQueryLoadTypeChange}
                allDeltaColumn={allDeltaColumn}
                handleBigQueryDeltaColumnChange={
                  handleBigQueryDeltaColumnChange
                }
                handleBigQueryPartionChange={handleBigQueryPartionChange}
                allPartitionColumn={allPartitionColumn}
                handleBigQueryPartitioningChange={
                  handleBigQueryPartitioningChange
                }
                handleOutputTableName={handleOutputTableName}
                //---
                excatData={excatData}
                handleExcatChange={handleExcatChange}
                isRequiredFieldFilled={isRequiredFieldFilled}
                excatTableHead={excatTableHead}
                myExcatloadType={myExcatloadType}
                excatTableData={excatTableData}
                excatTableOptions={excatTableOptions}
                handleSwitchChange={handleSwitchChange}
                handleSyncModeChange={handleSyncModeChange}
                handlePrimaryKeyChange={handlePrimaryKeyChange}
                //---------------------------------
                handleScriptNameChange={handleScriptNameChange}
                handleAddMoreTask={handleAddMoreTask}
                handleRemoveFromUI={handleRemoveFromUI}
                tags={tags}
                handleTagsChange={handleTagsChange}
                alertValue={alertValue}
                handleAlertValueChange={handleAlertValueChange}
                emailto={emailto}
                handleEmailToChange={handleEmailToChange}
                emailcc={emailcc}
                handleEmailccChange={handleEmailccChange}
                emailsubject={emailsubject}
                handleEmailSubjectChange={handleEmailSubjectChange}
                emailmessage={emailmessage}
                handleEmailMessageChange={handleEmailMessageChange}
                slackURL={slackURL}
                handleSlackURLChange={handleSlackURLChange}
                teamsURL={teamsURL}
                handleTeamsURLChange={handleTeamsURLChange}
                handlePatchJob={handlePatchJob}
              />
            </StyledDashboardContentFlex>
          </div>
        </StyledDashboardContentFlex>
      </StyledJobContainer>
    </StyledPortalContent>
  );
};

export default EditFormComponent;
