import React, { useState } from "react";
import { ConnectorScreens } from "../../../index";
import { useTranslation } from "react-i18next";

const S3Connector = ({
  inputData,
  S3Data,
  handleChange,
  handleS3SourceBucketChange,
  handleS3SourceBucketBlur,
  s3fileNameError,
  s3taskPartitionColumn,
  handleS3TaskPartitionColumn,
  onClose,
}) => {
  const useTaskPartition = useState(true);
  const useSourceBucket = useState(true);

  const { t } = useTranslation();

  return (
    <ConnectorScreens
      HeaderText={t("jobs.form.inputs.task.modal.connectors.bucket.s3.header")}
      inputData={inputData}
      data={S3Data}
      handleChange={handleChange}
      useSourceBucket={useSourceBucket}
      formatType={"Required Format: bucket-name/file"}
      sourceBucketFormat={"s3://"}
      handleSourceBucketChange={handleS3SourceBucketChange}
      handleSourceBucketBlur={handleS3SourceBucketBlur}
      sourceBucketError={s3fileNameError}
      sourceBucketPlaceholder={"e.g: bucket-name/file"}
      useTaskPartition={useTaskPartition}
      taskPartitionColumn={s3taskPartitionColumn}
      handleTaskPartitionColumn={handleS3TaskPartitionColumn}
      btnOnClick={onClose}
      buttonText={t("reusable_components.buttons.labels.save")}
    />
  );
};

export default S3Connector;
