const axiosErrorHandler = (error) => {
    if (error.response) {
        console.error("Error Response:", error.response.data);
        return {
            status: error.response.status,
            message: error.response.data?.error || "An error occurred",
            data: error.response.data,
        };
    } else if (error.request) {
        console.error("No response received:", error.request);
        return {
            status: null,
            message: "No response from server. Please try again later.",
        };
    } else {
        console.error("Request error:", error.message);
        return {
            status: null,
            message: error.message,
        };
    }
};

export default axiosErrorHandler;