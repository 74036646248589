import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { Flex, PreLoader } from "../../../index";
import { useSelector } from "react-redux";
import SQLError from "./SQLError";
import SQLSuccess from "./SQLSuccess";

// Styled components outside the main component to avoid re-creation
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#cde5ff",
    opacity: 1,
    color: "#001F25",
    textAlign: "left",
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
    color: "#001F25",
    font: "normal 500 14px/20px 'Poppins', sans-serif",
    letterSpacing: "0.1px",
  },
  "td, th": {
    border: 0,
  },
}));

// Memoized Row component
const Row = React.memo(({ row, rowIndex }) => {
  return (
    <TableRow>
      <StyledTableCell sx={{ padding: "0 8px" }}>{rowIndex}</StyledTableCell>
      {Object.keys(row).map((header, colIndex) => (
        <StyledTableCell key={colIndex} sx={{ padding: "0 8px" }}>
          {row[header] === null || row[header] === ""
            ? "Nothing to show"
            : row[header].value
              ? row[header].value
              : row[header]}
        </StyledTableCell>
      ))}
    </TableRow>
  );
});

const SQLResultsTable = ({ selectedSQLTab }) => {
  const { postQueryError, postQueryLoader, getQueryResultLoader, getQueryResultSuccess } = useSelector(
    (state) => state.users
  );

  const renderTable = () => {
    if (Array.isArray(selectedSQLTab.results) && selectedSQLTab.jobId !== null) {
      if (selectedSQLTab.results.length > 0) {
        return (
          <div
            style={{
              width: "100%",
              overflow: "auto",
              maxHeight: "288px",
              position: "relative",
            }}
          >
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ minWidth: 700, maxHeight: "288px" }}>
                <Table aria-label="sticky table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell sx={{ padding: "8px", position: "sticky" }}>
                        Rows
                      </StyledTableCell>
                      {Object.keys(selectedSQLTab.results[0]).map((header, index) => (
                        <StyledTableCell key={index} align="center" style={{ minWidth: 100 }} sx={{ padding: "8px" }}>
                          {header}
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {selectedSQLTab.results.map((row, rowIndex) => (
                      <Row key={rowIndex} row={row} rowIndex={rowIndex} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        );
      } else {
        return <SQLError value={"0 rows as result for the query sent"} />;
      }
    } else if (selectedSQLTab.results !== null && Object.keys(selectedSQLTab.results).length > 0) {
      return <p>{selectedSQLTab.results.message}</p>;
    }
  };

  return (
    <Flex column style={{ width: "100%" }}>
      {postQueryLoader || getQueryResultLoader ? (
        <PreLoader />
      ) : postQueryError ? (
        <SQLError value={postQueryError || "0 rows as result for the query sent"} />
      ) : Array.isArray(selectedSQLTab.results) && selectedSQLTab.jobId !== null ? (
        renderTable()
      ) : getQueryResultSuccess?.message ? (
        <SQLSuccess value={getQueryResultSuccess.message} />
      ) : null}
    </Flex>
  );
};

export default SQLResultsTable;
