import { useState, useRef } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import Papa from "papaparse";
import { useDispatch, useSelector } from "react-redux";
import useGetToken from "../../cognito/useGetToken";
import { postManualUploadData } from "../../redux/actions/usersActions";

const useUpload = () => {
  const dispatch = useDispatch();
  const [errMsg, setErrMsg] = useState("");
  const [GCPSuccessMessage, setGCPSuccessMessage] = useState("");
  const [GCPErrorMessage, setGCPErrorMessage] = useState("");
  const { environmentVariables } = useSelector((state) => state.users);
  const { allCustomers } = useSelector((state) => state.customers);
  const { token } = useGetToken();
  const group = localStorage.getItem("group");

  const [files, setFiles] = useState([]);
  const [csvData, setcsvData] = useState([]);
  const errRef = useRef();

  // dropZone
  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      accept: {
        "text/csv": [".csv"],
        "text/xlsx": [".xlsx"],
      }, //accept both csv and xls
      maxFilesize: 2,
      maxFiles: 3,
      maxSize: 10 * 1000 * 1024 * 1024, // 10GB
      onDrop: async (acceptedFiles) => {
        setFiles(acceptedFiles.map((file) => Object.assign(file)));
        for (const file of acceptedFiles) {
          if (file.type === "text/csv") {
            const parsedData = await new Promise((resolve) => {
              Papa.parse(file, {
                complete: (result) => {
                  resolve(result.data);
                },
                header: true,
              });
            });
            setcsvData(parsedData);
          }
        }
      },
    });

  // delete from the ui
  const handleRemove = (name) => {
    setFiles(files.filter((file) => file.name !== name));
  };
  const handlePostCSVData = async (e) => {
    e.preventDefault();
    const cloudProvider =
      allCustomers[0].cloudCredential[0].cloudProvider.toLowerCase();
    let fileName;
    let file;
    files.forEach((item) => {
      fileName = item.name;
      file = item;
    });
    if (cloudProvider === "aws") {
      try {
        const formData = new FormData();
        files.forEach((file) => {
          formData.append("fileName", file);
        });
        formData.append("cloudProvider", cloudProvider);
        formData.append(
          "ssmParameterName",
          allCustomers[0].cloudCredential[0].ssmPathParam,
        );
        formData.append("group", group);

        if (token) {
          dispatch(
            postManualUploadData(
              formData,
              token,
              environmentVariables.REACT_APP_BASE_URL_API_CORE,
            ),
          );
          setErrMsg("");
        }
      } catch (error) {
        console.log(error);
      }
    } else if (cloudProvider === "gcp") {
      try {
        const formData = new FormData();
        formData.append("fileName", fileName);
        formData.append("cloudProvider", cloudProvider);
        formData.append(
          "ssmParameterName",
          allCustomers[0].cloudCredential[0].ssmPathParam,
        );
        formData.append("group", group);
        if (token) {
          const headers = {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          };
          try {
            // First request to get the presigned URL
            const response = await axios.post(
              `${environmentVariables.REACT_APP_BASE_URL_API_CORE}/manual-upload`,
              formData,
              {
                headers,
              },
            );
            const getPresignedUrl = response.data;
            const { result } = getPresignedUrl;
            if (result) {
              const fileHeaders = {
                "Content-Type": "application/octet-stream",
                Authorization: `Bearer ${token}`,
              };
              try {
                await axios.put(result, file, {
                  headers: fileHeaders,
                });

                setGCPSuccessMessage("File uploaded successfully");
              } catch (error) {
                console.log(error);
              }
            }
          } catch (error) {
            console.log(error);
            setGCPErrorMessage("Upload Failed");
          }
          setErrMsg("");
        }
      } catch (error) {
        setGCPErrorMessage("Upload Failed");
      }
    }
  };
  return {
    files,
    errMsg,
    csvData,
    handleRemove,
    fileRejections,
    getRootProps,
    isDragActive,
    getInputProps,
    errRef,
    handlePostCSVData,
    GCPErrorMessage,
    GCPSuccessMessage,
  };
};

export default useUpload;