import React from "react";
import { MdCheckCircleOutline } from "react-icons/md";
import { StyledDashboardContentFlex } from "../../../styles/Login.Styled";
import { SuccessContainer } from "../Styles/SuccessStyle";

const SQLSuccess = ({ value, value2, value3 }) => {
    return (
        <SuccessContainer>
            <MdCheckCircleOutline className="error_icon" size={"1.5em"} />
            <StyledDashboardContentFlex
                column
                style={{ gap: "1em", justifyContent: "center" }}
            >
                <p>{value}</p>
                {value2 ? <p>{value2}</p> : null}
                {value3 ? <p>{value3}</p> : null}
            </StyledDashboardContentFlex>
        </SuccessContainer>
    );
};

export default SQLSuccess;
