import createAxiosInstance from "../api/axiosInstance";

export const axiosClientLacasApi = (baseURL) => createAxiosInstance(baseURL);

export const axiosGetJobs = (baseURL) => createAxiosInstance(baseURL);

export const axiosGetCount = (baseURL) => createAxiosInstance(baseURL);

export const axiosClientAWSRegionApi = (baseURL) => createAxiosInstance(baseURL);

export const axiosClientBundlesApi = (baseURL) => createAxiosInstance(baseURL);

export const axiosClientCustomerApi = (baseURL) => createAxiosInstance(baseURL);

export const axiosGetTable = (baseURL) => createAxiosInstance(baseURL);

export const axiosTransformData = (baseURL) => createAxiosInstance(baseURL);

export const axiosExecuteQuery = (baseURL) => createAxiosInstance(baseURL);

export const axiosGlueMetadata = (baseURL) => createAxiosInstance(baseURL);

export const axiosCatalogApi = (baseURL, token) =>
  createAxiosInstance(baseURL, { Authorization: `Bearer ${token}` });

export const axiosPostLlmInput = (baseURL, token) =>
  createAxiosInstance(baseURL, { Authorization: `Bearer ${token}` });

export const axiosPbiAccessToken = (baseURL, token) =>
  createAxiosInstance(baseURL, { Authorization: `Bearer ${token}` });
