import { useState, useEffect } from 'react'

import { CognitoUserPool } from 'amazon-cognito-identity-js'

import { toast } from "react-toastify";
import { CognitoJwtVerifier } from 'aws-jwt-verify'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useGetPoolData } from '../../clientRequest/env-variable'

const useGetToken = () => {
  const [token, setToken] = useState('')
  const [currentUser, setCurrentUser] = useState('')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const getPoolData = useGetPoolData()

  useEffect(() => {
    const checkSessionExpiration = async () => {
      const UserPool = new CognitoUserPool(getPoolData)
      const cognitoUser = UserPool.getCurrentUser()
      if (cognitoUser) {
        cognitoUser.getSession(async (err, session) => {
          if (err) {
            console.error('Error fetching session:', err)
            return
          }
          if (session.isValid()) {
            const jwtToken = session.getIdToken().jwtToken

            // Decode the JWT token to see the payload
            const base64Url = jwtToken.split('.')[1] // Get the payload part of the JWT
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
            const payload = JSON.parse(window.atob(base64))

            // Check if token has expired
            const currentTime = Math.floor(Date.now() / 1000) // Current time in seconds
            if (currentTime < payload.exp) {
              const user = cognitoUser.username.split('@')[0]
              const capitalized = user.charAt(0).toUpperCase() + user.slice(1)
              setCurrentUser(capitalized)

              // Verifying token (optional step)
              const verifier = CognitoJwtVerifier.create({
                userPoolId: getPoolData.UserPoolId,
                tokenUse: 'id',
                clientId: getPoolData.ClientId
              })

              try {
                await verifier.verify(jwtToken)
                setToken(jwtToken)
              } catch (verifyError) {
                cognitoUser.signOut()
                navigate('/')
              }
            } else {
              toast.warning('Your session has expired. Please log in again.', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
                pauseOnFocusLoss: false
              })
              cognitoUser.signOut()
              navigate('/')
            }
          } else {
            toast.warning('Your session has expired. Please log in again.', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
              pauseOnFocusLoss: false
            })

            cognitoUser.signOut()
            navigate('/')
          }
        })
      }
    }

    if (getPoolData.ClientId) {
      checkSessionExpiration()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getPoolData.ClientId])

  // useEffect(() => {
  //   const checkSessionExpiration = async () => {
  //     const UserPool = new CognitoUserPool(getPoolData);
  //     const cognitoUser = UserPool.getCurrentUser();
  //     if (cognitoUser) {
  //       cognitoUser.getSession(async (err, session) => {
  //         if (err) {
  //           throw err;
  //         } else {
  //           if (session.isValid()) {
  //             const jwtToken = session.getIdToken().jwtToken;
  //             const user = cognitoUser.username.split("@")[0];
  //             const capitalized = user.charAt(0).toUpperCase() + user.slice(1);
  //             setCurrentUser(capitalized);
  //             const verifier = CognitoJwtVerifier.create({
  //               userPoolId: getPoolData.UserPoolId,
  //               tokenUse: "id",
  //               clientId: getPoolData.ClientId,
  //             });
  //             const payload = await verifier.verify(jwtToken);
  //             if (payload) {
  //               setToken(jwtToken);
  //             } else {
  //               cognitoUser.signOut();
  //               navigate("/");
  //             }
  //           } else {
  //             cognitoUser.signOut();
  //             navigate("/");
  //           }
  //         }
  //       });
  //     }
  //   };

  //   if (getPoolData.ClientId) {
  //     checkSessionExpiration();
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dispatch, getPoolData.ClientId]);
  return {
    token,
    currentUser
  }
}

export default useGetToken
