/* eslint-disable no-unused-vars */

import { useState, useEffect, useRef, Suspense, lazy } from "react";
import useGetToken from "../../cognito/useGetToken";
import "react-toastify/dist/ReactToastify.css";
import { LuDownload } from "react-icons/lu";
import {
  PiCaretDownLight,
  PiCaretRightLight,
  PiPlayLight,
  PiPlusBold,
} from "react-icons/pi";
import {
  HomeSvg,
  StyledDashboardContentFlex,
  StyledPortalContent,
  Tabs,
  Theme,
  TransformationSvg,
} from "../../index";
import "react-toastify/dist/ReactToastify.css";
import AceEditor from "react-ace";
import { StyledButton } from "../../reusableComponents/UIComponents/ButtonTonalIcon";
import ButtonFilledIcon from "../../reusableComponents/UIComponents/ButtonFilledIcon";
import ButtonOutlineIcon from "../../reusableComponents/UIComponents/ButtonOutlineIcon";
import DbSideBar from "./Components/DbSideBar";
import SQLResultsTable from "./Components/SQLResultsTable";
import { resetQueryError } from "../../redux/actions/usersActions";
import {
  HeaderTabButton,
  QueryOptionsContainer,
  QueryTab,
  SQLEditorContainer,
  SQLTransformationContent,
  TransformationResultContainer,
} from "./Styles/TransformationStyle";
import { useDispatch, useSelector } from "react-redux";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/theme-sqlserver";
import "ace-builds/src-noconflict/mode-sql";
import useOnLoad from "../hooks/useOnLoad";
import { useTranslation } from "react-i18next";
import { fetchUserScripts } from "../../redux/actions/customerAction";

const TransformationPresenter = ({
  getColumns,
  selectedSQLTab,
  SQLTab,
  SQLTabError,
  setSQLTab,
  openScriptTab,
  handleQueryTabSelect,
  handleUpdateQueryValue,
  handleAddNewQueryTab,
  isOpenResults,
  handleOpenResults,
  handleRunSQL,
  resetQueryTabError,
  scriptName,
  handleScriptNameChange,
  open,
  handleClickOpen,
  handleScheduleModal,
  handleExportCSV,
  handleClose,
}) => {
  const SaveModal = lazy(() => import("./SaveModal"));
  const [topDivHeight, setTopDivHeight] = useState(26);
  const [bottomDivHeight, setBottomDivHeight] = useState(44);
  const [isDragging, setIsDragging] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const fill = Theme.colors.blueColor;
  const size = 18;
  const { allCustomersLoader, allCustomers } = useSelector(
    (state) => state.customers,
  );
  const tabData = [
    {
      to: "/dataManagement",
      icon: <HomeSvg fill={fill} width={size} height={size} />,
      label: t("data_management.transformation.header.home"),
    },
    {
      to: "/transformation",
      icon: <TransformationSvg fill={fill} width={size} height={size} />,
      label: t("data_management.transformation.header.transformation"),
    },
  ];
  const { metaDataTables, metaDataColumns } = useSelector(
    (state) => state.metaData,
  );

  useEffect(() => {
    const mouseUpHandler = () => {
      setIsDragging(false);
    };

    const mouseMoveHandler = (event) => {
      if (isDragging) {
        setDivPosition(event);
      }
    };

    document.body.addEventListener("mouseup", mouseUpHandler);
    document.body.addEventListener("mousemove", mouseMoveHandler);

    return () => {
      document.body.removeEventListener("mouseup", mouseUpHandler);
      document.body.removeEventListener("mousemove", mouseMoveHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);

  const getDivPosition = (event) => {
    const selection = window.getSelection().toString();
    setIsDragging(true);
  };
  const setDivPosition = (event) => {
    if (isDragging) {
      const containerTop = document
        .querySelector(".container")
        .getBoundingClientRect().top;
      const mousePosition = event.clientY;

      const topDivNewHeight =
        ((mousePosition - containerTop) / window.innerHeight) * 100;
      const bottomDivNewHeight = 100 - topDivNewHeight;

      if (topDivNewHeight >= 10 && bottomDivNewHeight >= 10) {
        setTopDivHeight(topDivNewHeight);
        setBottomDivHeight(bottomDivNewHeight);
      }
    }
  };

  const handleFocus = (id) => {
    if (postQueryError) {
      setSQLTab((prevSQLTab) => {
        const updatedTab = prevSQLTab.map((item) => {
          if (item.id === selectedSQLTab.id) {
            return {
              ...item,
              jobId: null,
            };
          }
          return item;
        });
        return updatedTab;
      });
    }
    dispatch(resetQueryError());
    resetQueryTabError(id);
  };

  const [editorKey, setEditorKey] = useState(1);

  useEffect(() => {
    setEditorKey((prev) => prev + 1);
  }, [metaDataTables]);

  const { load } = useOnLoad();

  const editorRef = useRef(null); // Reference to the editor instance
  const bundleRef = useRef(null);
  const handleEditorUnmount = () => {
    const editor = editorRef.current;
    if (editor) {
      editor
        .getSession()
        .selection.off("changeSelection", handleSelectionChange);
    }
  };
  // ...
  const onLoad = (editor) => {
    editorRef.current = editor;
    editor.setOptions({
      enableBasicAutocompletion: true,
      enableLiveAutocompletion: true,
    });
    if (metaDataTables) {
      load(metaDataTables, metaDataColumns);
    }
    // Store editor instance and set up the event listener
    editor
      .getSession()
      .selection.on("changeSelection", () => handleSelectionChange(editor));
  };

  const handleSelectionChange = (editor) => {
    const selection = editor.getSelectedText();
    const value = editor.getValue(); // Get the current value of the editor
    handleUpdateQueryValue(selectedSQLTab.id, value, selection);
  };

  const handleChange = (value) => {
    if (editorRef.current) {
      const editor = editorRef.current;
      const selection = editor.getSelectedText(); // Get the selection from the editor
      handleUpdateQueryValue(selectedSQLTab.id, value, selection);
    }
  };

  const [isDbSideBarOpen, setIsDbSideBarOpen] = useState(true);

  const { token } = useGetToken();
  const groupName = localStorage.getItem("group");
  const user = localStorage.getItem("created");
  const { environmentVariables, postQueryError } = useSelector(
    (state) => state.users,
  );
  useEffect(() => {
    if (token) {
      dispatch(
        fetchUserScripts(
          token,
          groupName,
          user,
          environmentVariables.REACT_APP_BASE_URL_API_CUSTOMER,
        ),
      );
    }
  }, [token, dispatch]);
  useEffect(() => {
    if (allCustomersLoader) {
      bundleRef.current = allCustomers[0].bundle;
    }
  }, [allCustomersLoader]);
  return (
    <StyledPortalContent style={{ width: "100%", padding: "1.5em 0.75em" }}>
      <div
        style={{
          width: "100%",
          height: "90vh",
        }}
      >
        <StyledDashboardContentFlex column>
          <StyledDashboardContentFlex justifyContent>
            <Tabs tabs={tabData} />
          </StyledDashboardContentFlex>
          <SQLEditorContainer>
            <DbSideBar
              getColumns={getColumns}
              SQLTab={SQLTab}
              openScriptTab={openScriptTab}
              selectedSQLTab={selectedSQLTab}
              isDbSideBarOpen={isDbSideBarOpen}
              setIsDbSideBarOpen={setIsDbSideBarOpen}
              bundleRef={bundleRef}
            />
            <SQLTransformationContent
              style={{ width: isDbSideBarOpen ? "calc(100% - 360px)" : "93%" }}
            >
              <QueryOptionsContainer>
                <div className="queryOptionsDivider">
                  <ButtonFilledIcon
                    label={t(
                      "data_management.transformation.presenter.buttons.run",
                    )}
                    disabled={!selectedSQLTab.runResult}
                    handleOnClick={() => handleRunSQL(selectedSQLTab.id)}
                    icon={
                      <PiPlayLight
                        size={"18px"}
                        color={`${Theme.colors.whiteColor}`}
                      />
                    }
                  />
                </div>
                <div className="queryOptionsDivider">
                  <StyledButton onClick={handleClickOpen}>
                    {t("data_management.transformation.presenter.buttons.save")}
                  </StyledButton>
                  <Suspense fallback={<div>Loading... </div>}>
                    {open && (
                      <SaveModal
                        open={open}
                        handleClose={handleClose}
                        SQLTab={SQLTab}
                        setSQLTab={setSQLTab}
                        scriptName={scriptName}
                        handleScriptNameChange={handleScriptNameChange}
                        selectedSQLTab={selectedSQLTab}
                        bundleRef={bundleRef}
                      />
                    )}
                  </Suspense>

                  <ButtonOutlineIcon
                    label={t(
                      "data_management.transformation.presenter.buttons.schedule",
                    )}
                    disabled={!selectedSQLTab.saved}
                    handleOnClick={handleScheduleModal}
                  />
                </div>
              </QueryOptionsContainer>
              <div className="topNavigationButtons">
                <div className="queryTabsContainer">
                  {SQLTab.map((item) => {
                    return (
                      <QueryTab
                        value={item.id}
                        key={`tab_id_${item.id}`}
                        background={item === selectedSQLTab}
                        onClick={() => {
                          handleQueryTabSelect(item.id);
                        }}
                      >
                        {item.name}
                      </QueryTab>
                    );
                  })}
                  <button
                    onClick={() => {
                      handleAddNewQueryTab();
                    }}
                  >
                    <PiPlusBold
                      size={"16px"}
                      color={`${Theme.colors.blueColor}`}
                    />
                  </button>
                </div>
              </div>
              <p className="error">{selectedSQLTab.error}</p>
              <p style={{ color: "red" }}>{SQLTabError}</p>
              {/* add scroll here  */}
              <div
                className="container"
                style={{ padding: "0", width: "100%" }}
              >
                <AceEditor
                  key={selectedSQLTab ? selectedSQLTab.id : "default"}
                  mode="sql"
                  onLoad={(editor) => {
                    handleEditorUnmount();
                    onLoad(editor);
                  }}
                  theme="sqlserver"
                  style={{
                    height: selectedSQLTab.runResult
                      ? `${topDivHeight}vh`
                      : "60vh",
                    width: "100%",
                    border: "1px solid #ccc",
                    position: "relative",
                  }}
                  onMouseDown={(event) => getDivPosition(event)}
                  onChange={handleChange}
                  id={`sql_input_search`}
                  name={`sql_editor_${
                    selectedSQLTab ? selectedSQLTab.id : "default"
                  }`}
                  fontSize={14}
                  showPrintMargin={true}
                  showGutter={true}
                  highlightActiveLine={true}
                  value={selectedSQLTab ? selectedSQLTab.query : ""}
                  onFocus={() => handleFocus(selectedSQLTab.id)}
                />

                {selectedSQLTab.runResult && (
                  <>
                    <div
                      id="divider"
                      className={isDragging ? "dragging" : ""}
                      onMouseDown={(event) => getDivPosition(event)}
                    ></div>
                    <TransformationResultContainer
                      id="bottomDiv"
                      style={{ height: `${bottomDivHeight}vh` }}
                      // onMouseDown={(event) => getDivPosition(event)}
                    >
                      <div className="plusResultContainer">
                        <HeaderTabButton
                          onClick={() => {
                            handleOpenResults();
                          }}
                          selected={isOpenResults}
                        >
                          {isOpenResults ? (
                            <PiCaretDownLight size={24} />
                          ) : (
                            <PiCaretRightLight size={24} />
                          )}
                          {t(
                            "data_management.transformation.presenter.footer.results",
                          )}
                        </HeaderTabButton>
                        <HeaderTabButton
                          style={{
                            textTransform: "uppercase",
                            cursor:
                              selectedSQLTab.results === null
                                ? "not-allowed"
                                : "pointer",
                          }}
                          disabled={selectedSQLTab.results === null}
                          onClick={() =>
                            handleExportCSV(
                              selectedSQLTab.results,
                              selectedSQLTab.name,
                            )
                          }
                        >
                          <LuDownload />
                          {t(
                            "data_management.transformation.presenter.footer.save_results",
                          )}
                        </HeaderTabButton>
                      </div>
                      {isOpenResults && (
                        <SQLResultsTable selectedSQLTab={selectedSQLTab} />
                      )}
                    </TransformationResultContainer>
                  </>
                )}
              </div>
            </SQLTransformationContent>
          </SQLEditorContainer>
        </StyledDashboardContentFlex>
      </div>
    </StyledPortalContent>
  );
};
export default TransformationPresenter;