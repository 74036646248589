import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Grid } from "@mui/material";
import { DocumentHeader } from "../index";
import DocumentNavigation from './DocumentNavigation';

const Documentation = () => {
  const [isActive, ] = useState(true);

  return (
    <div style={{ overflow: "hidden", maxHeight: "100vh" }}>
      <Grid xs={12} style={{ position: "sticky", top: 0, margin: 0, zIndex: 1 }}>
        <DocumentHeader />
      </Grid>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{ position: "fixed" }}>
        <Grid item xs={2.5} md={2.5}>
          <DocumentNavigation />
        </Grid>
        <Grid item xs={9.5} md={9.5} style={{ marginTop: '10px' }}>
          <Outlet />
        </Grid>
      </Grid>
    </div>
  );
};

export default Documentation;